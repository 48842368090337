import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '../Styles/Revision.css';

import {Link} from 'react-router-dom';

import { FaMinus } from "@react-icons/all-files/fa/FaMinus";
function Revision_Pastpaper() {
  return (
    <div>
      <div className="Revision-wrapper">
        <h5 className='mb-3'>Revision</h5>
        <div className='row mb-3'>
          <div className='col-md-2'>
            <div className="Revision-right1">
              <select className="dropdown-toggle " >
                <option value="chemistry">Chemistry</option>
                <option value="physics">Physics</option>
                <option value="biology">Biology</option>
                <option value="maths">Maths</option>
              </select>
            </div>
          </div>
          <div className='mar-right col-md-3 text-right'>
           <Link to="/revision">
            <button className='revision-pastpaper-buttons btn  btn-sm'id="selftest1">
             Selftest
            </button>
          </Link>
            <Link to="/rpastpaper">
              <button className='revision-pastpaper-buttons btn  btn-sm'id="pastpaper1">
                Pastpaper
              </button>
            </Link>
            <Link to="/rworksheet">
              <button className=' revision-pastpaper-buttons btn  btn-sm'id="worksheet">
                Worksheet
              </button>
            </Link>
          </div>
        </div>
        <div className='row'>
          <div className='row col-md-8'>       
              <div className='revision-pastpaper-table-data'>
                <table className='table'>
                  <thead>
                    <th >Chapter name</th>
                  </thead>
                  <tbody Style="margin-left:10px" >
                    <tr>    
                      <td><span>1. The particle model</span></td>
                    </tr>
                    <tr>          
                      <td><span>2. Melting and freezing</span></td>
                    </tr>
                    <tr>                  
                      <td><span>3. Pure and impure</span></td>
                    </tr>
                    <tr>                 
                      <td><span>4. Particles</span></td>
                    </tr>
                    <tr>              
                      <td><span>5. Organic Chemistry</span></td>
                    </tr>
                    <tr>               
                      <td><span>6. Oxidation</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
         
          <div className='col-md-4'>
            <div className='revision-pastpaer-right-data row '>
              <div className='col-md-3'>
                <table className='table mini-tab'>
                  <thead><th>Com</th></thead>
                  <tbody>
                    <tr>
                      <td><img src="\SqillUPImages\Tickmark.svg" alt="" /></td>
                    </tr>
                    <tr>
                      <td><FaMinus /></td>
                    </tr>
                    <tr>
                      <td><img src="\SqillUPImages\Tickmark.svg" alt="" /></td>
                    </tr>
                    <tr>
                      <td><img src="\SqillUPImages\Tickmark.svg" alt="" /></td>
                    </tr>
                    <tr>
                      <td><img src="\SqillUPImages\Tickmark.svg" alt="" /></td>
                    </tr>
                    <tr>
                      <td><FaMinus /></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className='col-md-3'>
                <table className='table mini-tab'>
                  <thead>
                    <th>
                      Redo
                    </th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Start</td>
                    </tr>
                    <tr>
                      <td><FaMinus /></td>
                    </tr>
                    <tr>
                      <td>Start</td>
                    </tr>
                    <tr>
                      <td>Start</td>
                    </tr>
                    <tr>
                      <td>Start</td>
                    </tr>
                    <tr>
                      <td><FaMinus /></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className='col-md-3'>
                <table className='table mini-tab'>
                  <thead>
                    <th>
                      Score
                    </th>
                  </thead>
                  <tbody>
                    <tr>
                      <td >15</td>
                    </tr>
                    <tr>
                      <td>09</td>
                    </tr>
                    <tr>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td>20</td>
                    </tr>
                    <tr>
                      <td>18</td>
                    </tr>
                    <tr>
                      <td><FaMinus /></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className='col-md-3'>
                <div className='revision-pastpaer-Icon-Table'>
                  <table className='table mini-tab'>
                    <thead>
                      <th>
                        Notes
                      </th>
                    </thead>
                    <tbody>
                      <tr>
                        <td><img src="\SqillUPImages\Path 5287.svg" alt="" /></td>
                      </tr>
                      <tr>
                        <td><img src="\SqillUPImages\Path 5287.svg" alt="" /></td>
                      </tr>
                      <tr>
                        <td><img src="\SqillUPImages\Path 5287.svg" alt="" /></td>
                      </tr>
                      <tr>
                        <td><img src="\SqillUPImages\Path 5287.svg" alt="" /></td>
                      </tr>
                      <tr>
                        <td><img src="\SqillUPImages\Path 5287.svg" alt="" /></td>
                      </tr>
                      <tr>
                        <td><img src="\SqillUPImages\Path 5287.svg" alt="" /></td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>

  )
}

export default Revision_Pastpaper