import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Courses.css";
import { useNavigate } from "react-router-dom";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useLocation } from "react-router-dom";

function Submit() {
  const [pageNumber, setPageNumber] = useState(1);
  //const [percentage, setPercentage] = useState(0);
  const location = useLocation();

  useEffect(() => {
    let interval = null;
    if (pageNumber < 3) {
      interval = setInterval(() => {
        setPageNumber((pageNumber) => pageNumber + 1);
      }, 5000);
    } else if (pageNumber === 3) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [pageNumber]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (percentage < 100) {
  //       setPercentage(percentage + 1);
  //     }
  //   }, 50);
  //}, [percentage]);

  let navigate = useNavigate();
  const handelRedoSubmit = () => {
    let lessonInfo = {
      lessonid: location.state.lessonId,
    };

    navigate("/selftest", { state: { lessonId: lessonInfo } });
  };
  const percentage = Math.ceil(
    (location.state.result * 100) / location.state.total
  );

  return (
    <div>
      {pageNumber === 1 ? (
        <div className="submit_card">
          <img src="/SqillUPImages/Check_green_circle.png" alt="" />
          <h6>Test Completed.</h6>
          <p>You have successfully completed your test!</p>
        </div>
      ) : pageNumber === 2 ? (
        <div className="submit_card1">
          <CircularProgressbar 
            value={`${percentage}%`}
            text={`${percentage}%`}            
          />
          <h6>Congratulations</h6>
          <h5>
            You have successfully completed your test and you have scored.
          </h5>
          <h4>
            <span>{location.state.result}</span>/{location.state.total}
          </h4>
          <p>Score</p>
        </div>
      ) : pageNumber === 3 ? (
        <div className="submit_card2">
          <img src="\SqillUPImages\Redo Img.svg" alt="" />
          <h4>You have completed your test.</h4>
          <p>You can give the test again , by clicking on the redo button.</p>
          <button onClick={handelRedoSubmit}>Redo</button>
        </div>
      ) : (
        ""
      )}
      <></>
    </div>
  );
}

export default Submit;
