import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '../Styles/Curriculum.css';

import {Link} from 'react-router-dom';

import { RiDownload2Fill } from "@react-icons/all-files/ri/RiDownload2Fill";
function Curriculum() {
  return (
    <>
      <div className="curriculum-wrapper">
        <div className="row  ">
          <div className="col-md-12 d-flex">
            <div className="col-md-6">
              <div className="Left-curriculum">
                <h5>Curriculum</h5>

              </div>
            </div>
            <div className="col-md-6">
              <div className="Right-curriculum d-md-flex justify-content-md-end">
                <p className="curriculum_Hide">Download Ks3 syllabus for AQA</p>
                <button>Download syllabus<span><RiDownload2Fill /></span></button>
              </div>
            </div>
          </div>
          <div className='col-md-2'>
            <div className="curriculum-dropdown">
              <select className="dropdown-toggle " >
              <option value="chemistry">Chemistry</option>
                <option value="physics">Physics</option>
                <option value="biology">Biology</option>
                <option value="maths">Maths</option>
              </select>
            </div>
          </div>
          <div className="row mt-4">
            <div className='col-md-8 row'>
              <div className="col-md-3">
                <div className='Curriculum-List'>
                  <ol className='Curriculum-Decimal'>
                  <Link to="/curriculum"> <li >State of matter</li></Link>
                  <Link to="/meetingandfreezing"> <li className='active'>Melting and freezing</li></Link>
                    <li>Pure and impure</li>
                    <li>Particles</li>
                    <li>Organic Chemistry</li>
                    <li>Oxidation</li>
                  </ol>
                </div>
              </div>
              <div className='col-md-9'>
                <div className='Curriculum-table'>
                  <table className='table'>
                    <thead>
                      <th>Lesson Code</th>
                      <th>Lesson name</th>
                      <th>Description</th>
                    </thead>
                    <tbody>
                      <tr>
                        <td className='Curriculum-title'>1.1</td>
                        <td>State of matter 1</td>
                        <td>Properties and behaviour of state of matter.</td>
                      </tr>
                      <tr>
                        <td className='Curriculum-title'>1.2</td>
                        <td>Melting and freezing</td>
                        <td>Particles explanations for properties.</td>
                      </tr>
                      <tr>
                        <td className='Curriculum-title'>1.3</td>
                        <td>Particles and there behaviour</td>
                        <td>Changes of state and energy.</td>
                      </tr>
                      <tr>
                        <td className='Curriculum-title'>1.4</td>
                        <td>Gas pressure</td>
                        <td>Melting and boiling Point.</td>
                      </tr>

                      <tr>
                        <td className='Curriculum-title'>1.5</td>
                        <td>Diffusion</td>
                        <td>How do particles create pressure in gas.</td>
                      </tr>

                      <tr>
                        <td className='Curriculum-title'>1.6</td>
                        <td>Chemical changes</td>
                        <td>Example and particle explanations.</td>
                      </tr>

                      <tr>
                        <td className='Curriculum-title'>1.7</td>
                        <td>Extension</td>
                        <td>How do particles create pressure in gas.</td>
                      </tr>

                      <tr>
                        <td className='Curriculum-title'>1.8</td>
                        <td>State of matter 2</td>
                        <td>Difference between chemical and physical.</td>
                      </tr>

                      <tr>
                        <td className='Curriculum-title'>1.9</td>
                        <td>Gas pressure 2</td>
                        <td>How do particles create pressure in gas.</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="Curriculum-right-table">
                <table className="table">
                  <thead>
                    <th>Selftest</th>
                    <th>Video</th>
                    <th>Short notes</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td><img src="\SqillUPImages\Tickmark.svg" alt="" /></td>
                      <td><img src="\SqillUPImages\Tickmark.svg" alt="" /></td>
                      <td><img src="\SqillUPImages\Tickmark.svg" alt="" /></td>
                    </tr>
                    <tr>
                      <td><img src="\SqillUPImages\Tickmark.svg" alt="" /></td>
                      <td><img src="\SqillUPImages\Tickmark.svg" alt="" /></td>
                      <td><img src="\SqillUPImages\Tickmark.svg" alt="" /></td>
                    </tr>
                    <tr>
                      <td><img src="\SqillUPImages\Tickmark.svg" alt="" /></td>
                      <td><img src="\SqillUPImages\Tickmark.svg" alt="" /></td>
                      <td><img src="\SqillUPImages\Tickmark.svg" alt="" /></td>
                    </tr>
                    <tr>
                      <td><img src="\SqillUPImages\Tickmark.svg" alt="" /></td>
                      <td><img src="\SqillUPImages\Tickmark.svg" alt="" /></td>
                      <td><img src="\SqillUPImages\Tickmark.svg" alt="" /></td>
                    </tr>
                    <tr>
                      <td><img src="\SqillUPImages\Tickmark.svg" alt="" /></td>
                      <td><img src="\SqillUPImages\path 3052.svg" alt=""/></td>
                      <td><img src="\SqillUPImages\Tickmark.svg" alt="" /></td>
                    </tr>
                    <tr>
                      <td><img src="\SqillUPImages\Tickmark.svg" alt="" /></td>
                      <td><img src="\SqillUPImages\path 3052.svg" alt=""  /></td>
                      <td><img src="\SqillUPImages\Tickmark.svg" alt="" /></td>
                    </tr>
                    <tr>
                      <td><img src="\SqillUPImages\Tickmark.svg" alt="" /></td>
                      <td><img src="\SqillUPImages\Tickmark.svg" alt="" /></td>
                      <td><img src="\SqillUPImages\Tickmark.svg" alt="" /></td>
                    </tr>
                    <tr>
                      <td><img src="\SqillUPImages\Tickmark.svg" alt="" /></td>
                      <td><img src="\SqillUPImages\Tickmark.svg" alt="" /></td>
                      <td><img src="\SqillUPImages\Tickmark.svg" alt="" /></td>
                    </tr>
                    <tr>
                      <td><img src="\SqillUPImages\Tickmark.svg" alt="" /></td>
                      <td><img src="\SqillUPImages\Tickmark.svg" alt="" /></td>
                      <td><img src="\SqillUPImages\Tickmark.svg" alt="" /></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="Curriculum-footer">
          <p><span>Note:</span> This is for guidance only</p>
        </div>
      </div>

    </>
  )
}

export default Curriculum