import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Notification_Badges.css";

import {
  studentNotification,
  studentviewNotification,
  notification_student,
} from "../Services";
//import Topbar from '../Pages/Topbar';
let initialValues = {
  payment_push_notification: "",
  payment_email_notification: "",
  payment_sms_notification: "",
  admin_push_notification: "",
  admin_email_notification: "",
  admin_sms_notification: "",
  user_id: "",
};

export default function Notification() {
  const [notification, setNotification] = useState(initialValues);
  const [stunotification, setStuNotification] = useState([]);

  useEffect(() => {
    studentviewNotification().then((data) => {
      setNotification(data);
    });
    notification_student().then((data) => {
      setStuNotification(data);
    });
  }, []);

  const notificationChange = (event) => {
    const obj = {
      user_id: notification.user_id,
      name: event.target.name,
      checked: event.target.checked,
    };

    setNotification({
      ...notification,
      [event.target.name]: event.target.checked,
    });
    studentNotification(obj).then((data) => {
      setNotification(data?.data);
    });
  };
  return (
    <div className="notifi-content-wrapper">
      <h5 className="mb-3">Notification</h5>
      <div className="row">
        <div className="col-md-8">
          {stunotification.length &&
            stunotification.map((data, index) => (
              <div className="notification">
                <div class="notification__item">
                  <div class="notification__column notification__column--left">
                    <div className="notfy-circle">{data.image}</div>
                    <div class="notification__info">
                      <span class="notification__company">{data.title}</span>
                      <span class="notification__title">
                        {data.description}
                      </span>
                    </div>
                  </div>
                  <div class="notification__column notification__column--right">
                    <span class="tag">{data.created_at}</span>
                  </div>
                </div>
                {/* <div class="notification__item bg-grey">
              <div class="notification__column notification__column--left">
                <div className='notfy-circle'>S</div>
                <div class="notification__info">
                  <span class="notification__company">John vender</span>
                  <span class="notification__title">Your student Lucifer Morningstar of chemistry subject has renew payment</span>
                </div>
              </div>
              <div class="notification__column notification__column--right">
                <span class="tag">
                  few seconds ago
                </span>
              </div>
            </div>
            <div class="notification__item">
              <div class="notification__column notification__column--left">
                <div className='notfy-circle yellow-cicle'>S</div>
                <div class="notification__info">
                  <span class="notification__company">John vender</span>
                  <span class="notification__title">Your student Lucifer Morningstar of chemistry subject has renew payment</span>
                </div>
              </div>
              <div class="notification__column notification__column--right">
                <span class="tag">
                  few seconds ago
                </span>
              </div>
            </div>
            <div class="notification__item bg-grey">
              <div class="notification__column notification__column--left">
                <div className='notfy-circle'>L</div>
                <div class="notification__info">
                  <span class="notification__company">Lephen Shaw</span>
                  <span class="notification__title">Your child Lucifer Morningstar package has upgraded.</span>
                </div>
              </div>
              <div class="notification__column notification__column--right">
                <span class="tag">
                  few seconds ago
                </span>
              </div>
            </div> */}
              </div>
            ))}
        </div>
        <div className="col-md-4">
          <div className="notification-settigs">
            <h5>Notification Setting</h5>
            <div className="notify-card">
              <p>Payment Notifications</p>

              {/* Push notifications */}
              <div class="switch_box box_1">
                <input
                  type="checkbox"
                  class="switch_1"
                  name="payment_push_notification"
                  checked={
                    notification?.payment_push_notification === "true"
                      ? true
                      : false
                  }
                  onClick={notificationChange}
                />
                <label>Push notifications</label>
              </div>
              {/* Push notificationsend */}

              {/* email notifications */}
              <div class="switch_box box_1">
                <input
                  type="checkbox"
                  class="switch_1"
                  name="payment_email_notification"
                  checked={
                    notification?.payment_email_notification === "true"
                      ? true
                      : false
                  }
                  onClick={notificationChange}
                />
                <label>Email notifications</label>
              </div>
              {/* email notifications end */}

              {/* text notifications */}
              <div class="switch_box box_1">
                <input
                  type="checkbox"
                  class="switch_1"
                  name="payment_sms_notification"
                  checked={
                    notification?.payment_sms_notification === "true"
                      ? true
                      : false
                  }
                  onClick={notificationChange}
                />
                <label>Text notifications</label>
              </div>
              {/* text notifications end */}

              <p>Admin Notifications</p>

              {/* Push notifications */}
              <div class="switch_box box_1">
                <input
                  type="checkbox"
                  class="switch_1"
                  name="admin_push_notification"
                  checked={
                    notification?.admin_push_notification === "true"
                      ? true
                      : false
                  }
                  onClick={notificationChange}
                />
                <label>Push notifications</label>
              </div>
              {/* Push notifications end */}

              {/* email notifications */}
              <div class="switch_box box_1">
                <input
                  type="checkbox"
                  class="switch_1"
                  name="admin_email_notification"
                  checked={
                    notification?.admin_email_notification === "true"
                      ? true
                      : false
                  }
                  onClick={notificationChange}
                />
                <label>Email notifications</label>
              </div>
              {/* email notifications end */}

              {/* text notifications */}
              <div class="switch_box box_1">
                <input
                  type="checkbox"
                  class="switch_1"
                  name="admin_sms_notification"
                  checked={
                    notification?.admin_sms_notification === "true"
                      ? true
                      : false
                  }
                  onClick={notificationChange}
                />
                <label>Text notifications</label>
              </div>
              {/* text notifications end */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
