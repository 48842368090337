export const baseURL = "https://api.smartht.co.uk/api/";

export const base = "https://api.smartht.co.uk";

//Login Page
export const studentLogin = "parentauth/studentLogin";

//Search Bar 
export const search ="studentSearch";

//Dashboard Page
export const notice ="viewStudentNotice";

export const greetings ="viewGreetingStudent";

export const score ="ViewStudentScore";
//Badges and Rewards API's
export const badges ="viewStudentBadge";

export const rewards ="viewStudentReward";
//MyProfile Page
export const studentProfile = "studentprofile/viewProfile";

//Student View all Page
export const studentViewAll = "ViewStudentSubject";

export const viewall="ViewStudentLessonScore";

export const viewscore ="viewChapterScore";

export const examType = "viewExamType";

export const allLessons = "viewStudentLesson";

export const ongoing_student ="ViewStudentOngoingLesson";

export const selfTest = "viewSelfTest";

export const chapter = "viewChapter";

export const worksheet = "viewWorkSheet";

export const qandA = "viewQnA";

export const examboard = "ViewStudentExamBoard";

export const pastpaper = "viewPastPaperStudent";

export const year = [
  2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022,
];

export const revision = "viewStudentRevision";

export const curriculum ="viewStudentCurriculum";

export const notification = "updateStudentNotificationSetting";

export const viewnotification ="viewStudentNotificationSetting";

export const studentnotification ="viewNotification";

export const glossary ="viewGlossary";

export const studentschooldetails="viewStudentSchool"

export const upcomingEvents ="viewStudentUpcomingEvent";