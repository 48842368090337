import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Courses.css";
import Loader from "../Helpers/Loader";
import {
  homeViewAll,
  studentChapterdata,
  studentViewAllLessons,
  ongoing_lessons,
} from "../Services";
import Moment from "react-moment";

import { AiFillPlayCircle } from "@react-icons/all-files/ai/AiFillPlayCircle";
import { RiRefreshFill } from "@react-icons/all-files/ri/RiRefreshFill";
import { AiFillHeart } from "@react-icons/all-files/ai/AiFillHeart";
import { AiFillCheckCircle } from "@react-icons/all-files/ai/AiFillCheckCircle";
import { AiOutlineHeart } from "@react-icons/all-files/ai/AiOutlineHeart";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { event } from "jquery";

function Courses() {
  const listCourses = [];
  const listOngoingCourses = [];
  const [selectLessons, setselectLessons] = useState(null);

  const [selectsubject, setSelectSubject] = useState("");
  const [subjects, setSubjects] = useState([]);

  const [selectchapeter, setSelectChapter] = useState("");
  const [chaptertype, setChaptertype] = useState([]);
  const [chaptertypeShow, setChaptertypeshow] = useState([]);
  const [activenav, setActiveNav] = useState("1");

  const [ongoing, setOngoing] = useState([]);

  const [subInfo, setSubInfo] = useState({ subject: "", chapter: "" });

  const handleSubjectChange = (e) => {
    setSelectChapter("");
    setSelectSubject(e.target.value);
    setSelectChapter("");
    let value = subjects.filter((s) => s.value == e.target.value);
    let val = subInfo;
    val.subject = value[0].label;
    val.chapter = "";
    setSubInfo(val);
    if (e.target.value) {
      let fil = chaptertype.filter((c) => c.sub == e.target.value);
      setChaptertypeshow(fil);
    } else {
      setChaptertypeshow(chaptertype);
    }
  };

  const handelChaptertype = (e) => {
    setSelectChapter(e.target.value);
    let value = chaptertype.filter((s) => s.value == e.target.value);
    let val = subInfo;
    val.chapter = value[0].label;
    setSubInfo(val);
    if (
      (e.target.value && selectsubject) ||
      (e.target.value === "" && selectsubject === "")
    ) {
      const obj = {
        subject_id: selectsubject ? selectsubject : "",
        chapter_id: e.target.value,
      };
      studentViewAllLessons(obj).then((data) => {
        setselectLessons(data);
        for (const [i, product] of data.entries()) {
          listCourses.push(<li>{product.name}</li>);
        }
      });
    }
  };
  const handelOngoing = (id) => {
    setActiveNav(id);
    ongoing_lessons("ongoing").then((data) => {
      for (const [i, product] of data.entries()) {
        listOngoingCourses.push({
          name: product?.lesson?.name,
          description: product?.lesson?.description,
        });
      }
      setselectLessons(listOngoingCourses);
    });
  };

  const handelComplted = (id) => {
    setActiveNav(id);

    ongoing_lessons("complete").then((data) => {
      for (const [i, product] of data.entries()) {
        listOngoingCourses.push({
          name: product?.lesson?.name,
          description: product?.lesson?.description,
        });
      }
      setselectLessons(listOngoingCourses);
    });
  };
  const handelAllWish = (id) => {
    setActiveNav(id);
  };

  const handelAllLessons = (id) => {
    setActiveNav(id);

    const obj = {
      subject_id: selectsubject ? selectsubject : "",
      chapter_id: selectchapeter ? selectchapeter : "",
    };
    studentViewAllLessons(obj).then((data) => {
      setselectLessons(data);
    });
  };
  //

  useEffect(() => {
    homeViewAll().then((data) => {
      setSubjects(data);
      var bindSubjects = [];
      data.forEach((element) => {
        bindSubjects.push({ label: element.name, value: element.id });
      });
      setSubjects(bindSubjects);
    });
  }, []);

  //Courses section Get AllLessons
  useEffect(() => {
    // let obj = {subject_id:}
    studentViewAllLessons(subInfo).then((data) => {
      setselectLessons(data);
      for (const [i, product] of data.entries()) {
        listCourses.push(<li>{product.name}</li>);
      }
    });
  }, []);

  //Chapter Content Dropdown
  useEffect(() => {
    studentChapterdata().then((data) => {
      setChaptertype(data);
      var bindchaptertype = [];
      data.forEach((element) => {
        bindchaptertype.push({
          label: element.name,
          value: element.id,
          sub: element.subject_id,
        });
      });
      setChaptertype(bindchaptertype);
      setChaptertypeshow(bindchaptertype);
    });
  }, []);

  let navigate = useNavigate();
  function ViewLesson(e, obj) {
    navigate("/particularsubject/" + obj.id + "/" + obj.chapter_id);
  }
  const calendarStrings = {
    lastDay: "[Yesterday at] LT",
    sameDay: "[Today at] LT",
    nextDay: "[Tomorrow at] LT",
    lastWeek: "[last] dddd [at] LT",
    nextWeek: "dddd [at] LT",
    sameElse: "L",
  };
  // const courseStyles = {};

  return (
    <div>
      <div className="courses-wrapper">
        <h5 className="mb-3">
          {subInfo.subject}
          {selectsubject && selectchapeter && <span className="v1"></span>}
          {subInfo.chapter}
        </h5>
        <div className="row mb-3">
          <div className="col-md-9">
            <div className="tabs mb-2">
              <ul className="courses_tabs">
                <li onClick={() => handelAllLessons("1")}>
                  <label for="tab1" role="button">
                    <span className="pl-2 pr-2 icon1">
                      <AiFillPlayCircle />
                    </span>
                    <span className="all-lessons">All Lessons</span>
                  </label>
                </li>

                {selectsubject && selectchapeter && (
                  <li onClick={() => handelOngoing("2")}>
                    <label for="tab2" role="button">
                      <span className="pl-2 pr-2 icon2">
                        <RiRefreshFill />
                      </span>
                      <span>Ongoing</span>
                    </label>
                  </li>
                )}
                {selectsubject && selectchapeter && (
                  <li onClick={() => handelAllWish("3")}>
                    <label for="tab3" role="button">
                      <span className="pl-2 pr-2 icon3">
                        <AiFillHeart />
                      </span>
                      <span>Wishlist</span>
                    </label>
                  </li>
                )}
                {selectsubject && selectchapeter && (
                  <li onClick={() => handelComplted("4")}>
                    <label for="tab4" role="button">
                      <span className="pl-2 pr-2 icon4">
                        <AiFillCheckCircle />
                      </span>
                      <span>Completed</span>
                    </label>
                  </li>
                )}
              </ul>

              <div className="slider">
                <div className="indicator"></div>
              </div>
              {selectsubject && selectchapeter && (
                <div className="slider1">
                  <div className="indicator"></div>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-3 d-flex ">
            <div className="courses-right1 ">
              <select
                className="dropdown-toggle"
                name="subject_id"
                onChange={handleSubjectChange}
              >
                <option value="">Select-Subject</option>
                {subjects.length &&
                  subjects.map((data, index) => (
                    <option value={data.value}>{data.label}</option>
                  ))}
              </select>
            </div>
            <div className="courses-right2">
              <select
                className="dropdown-toggle"
                name="subject_id"
                onChange={handelChaptertype}
                value={selectchapeter}
              >
                <option value="">Select-Chapter</option>
                {chaptertypeShow.length &&
                  chaptertypeShow.map((data, index) => (
                    <option value={data.value}>{data.label}</option>
                  ))}
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="course-content d-flex">
              {selectLessons != null ? (
                selectLessons.map((obj) => {
                  return (
                    <div className="col-md-2 col-sm-2 col-lg-2 col-xs-2">
                      <div class="card" onClick={() => ViewLesson(event, obj)}>
                        <div className="card-body">
                          <p class="card-title">
                            {obj.name}{" "}
                            <span>
                              <AiOutlineHeart />
                            </span>
                          </p>
                          <img
                            src="https://cdn.pixabay.com/photo/2014/04/02/16/20/chemistry-306984__340.png"
                            className="card-image card-title card-icon"
                            alt=""
                          />
                          <time>12:30</time>
                        </div>
                        <div className="card-footer">
                          <p id="p1">
                            <b>{obj.name}</b>
                          </p>
                          <div
                            id="description"
                            dangerouslySetInnerHTML={{
                              __html: obj.description,
                            }}
                          ></div>
                          {/* <p id="p3">{obj.overview}</p>&nbsp;&nbsp;&nbsp; */}
                          <p id="p3">250 views</p>
                          <p id="p4">
                            <li>
                              {" "}
                              <Moment calendar={calendarStrings}>
                                {obj.created_at}
                              </Moment>
                              {/* <Moment add={{ days: 1, hours: 12 }}> {obj.created_at}</Moment> */}
                            </li>
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Courses;
