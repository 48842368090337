import React, { useState, useEffect } from "react";
//import ParticularSubject from "./ParticularSubject";
import "../Styles/Courses.css";
import { Link } from "react-router-dom";
import { AiOutlineClose } from "@react-icons/all-files/ai/AiOutlineClose";
import { GenericPost } from "../Services";
function PostQuestion({ lessonInfo, setPopupOpen }) {


  // const[lessonId,setLessonId]=useState(lessonId);
  const [values, setValues] = useState("");
  const [errors, setErrors] = useState("");
  const [dataIsSubmit, setDataIsSubmit] = useState(false);

  const handelFormSubmit = (e) => {
    e.preventDefault();
    setDataIsSubmit(true);

    var data = new FormData();
    data.append("student_id", sessionStorage.getItem("loginUser"));
    data.append("subject_id", lessonInfo?.lessonid?.subjectId);
    data.append("lesson_id", lessonInfo?.lessonid?.lessonid);
    data.append("chapter_id", lessonInfo?.lessonid?.chapterId);
    data.append("question", values.comment);

    GenericPost(data, "createQnA").then((data) => {
      //  setQuestions(data);
      setPopupOpen(false);
    });
  };
  useEffect(() => {
    if (Object.keys(errors).length === 0 && dataIsSubmit) {
    }
  }, []);

  const handelChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const ClosePopup = () => {
    setPopupOpen(false);
  };

  return (
    <div>
      <div>
        {/* <ParticularSubject /> */}
        {/* <div className="overlay"></div> */}
      </div>
      <div className="row">
        <div className="Post-card">
          <div>
            <h5 className="text-center">Post Question </h5>

            <span onClick={ClosePopup}>
              <AiOutlineClose />
            </span>
          </div>
          <div className="Post-card-body">
            <textarea
              rows="4"
              cols="50"
              name="comment"
              form="usrform"
              placeholder="Type your question…"
              onChange={handelChange}
            />
          </div>
          <Link to="/particularsubject">
            <button onClick={handelFormSubmit}>Submit</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default PostQuestion;
