import React from 'react';

function ContactUs() {
  return (
    <div>
      <h1>Contact Page</h1>
    </div>
  )
}

export default ContactUs