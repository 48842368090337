import React, { useEffect, useState } from "react";
import "../Styles/Home.css";
import "bootstrap/dist/css/bootstrap.css";
import { Link } from "react-router-dom";
import { AiOutlineSearch } from "@react-icons/all-files/ai/AiOutlineSearch";
import { FaRegUser } from "@react-icons/all-files/fa/FaRegUser";
import { FaSignOutAlt } from "@react-icons/all-files/fa/FaSignOutAlt";
import { useNavigate } from "react-router-dom";
import { serach_bar, myProfile } from "../Services";

export default function Topbar() {
  const [searchdata, setSearchData] = useState("");
  const [value, setValue] = useState("");
  const [profile, setProfile] = useState("");
  const [hide, setHide] = useState();
  useEffect(() => {
    myProfile().then((data) => {
      setProfile(data);
    });
  }, []);
  const onChangesearch = (event) => {
    setValue(event.target.value);
    serach_bar(event.target.value).then((data) => {
      // console.log("55555555", data, event.target.value);
      if (event.target.value.trim().length) {
        setSearchData(data);
      } else setSearchData([]);
    });
  };

  let navigate = useNavigate();
  const handelLessons = (data) => {
    setValue(data.name);
    setSearchData([]);
    navigate("/particularsubject/" + data.id + "/" + data.chapter_id);
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg ">
        <div className="container-fluid ">
          <div className="col-md-12 col-lg-12 col-xs-12 d-flex ">
            <div className="offset-md-1 col-md-4 col-lg-4 col-xs-4">
              <form className="d-flex Searchbar">
                <div className="serch-icon">
                  <AiOutlineSearch />
                </div>

                <input
                  className="form-control me-2"
                  type="search"
                  value={value}
                  onChange={onChangesearch}
                  placeholder="Search for anything (Class, Subjects, Exams .etc)"
                  aria-label="Search"
                />
              </form>

              <div
                className="dropdown_data Searchbar"
                style={{ cursor: "pointer" }}
              >
                {searchdata.length > 0 &&
                  searchdata.slice(0, 3).map((data, index) => (
                    <p className="view_p" onClick={() => handelLessons(data)}>
                      {data.name}
                    </p>
                  ))}
              </div>
            </div>
            <div className="col-md-8 row Topbar-right">
              <div className="col-md-4">
                <div className="ml-auto">
                  <ul className="navbar-nav ">
                    <li className="nav-item Io-Notifications">
                      <Link to="/notification" className="text-dark">
                        <span>
                          <img src="\SqillUPImages\Notifications.svg" alt="" />
                        </span>
                      </Link>
                    </li>
                    <li className="nav-item Badges">
                      <Link to="/badges">
                        <img src="\SqillUPImages\BadgeTop.svg" alt="" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4 ">
                <div className="profile-dropdown ">
                  <ul className="navbar-nav ">
                    <li className="nav-item Profile">
                      <img src="\SqillUPImages\User_Profile.png" alt="" />
                      {/* <img src={profile.image} alt="" /> */}

                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        to="#"
                        className="nav-link myprofile_top_data"
                        data-toggle="dropdown"
                        title="Login User"
                      >
                        <p className="dropdown-toggle">
                          Hello, <b>{profile.full_name}</b>
                        </p>
                        <span>Student</span>
                      </Link>
                      <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right top-user">
                        <Link
                          to="/myprofile"
                          className="dropdown-item"
                          title="Role"
                        >
                          <span className="pr-2">
                            <FaRegUser />
                          </span>
                          My Profile
                        </Link>
                        <Link
                          to="/signout"
                          className="dropdown-item"
                          title="Phone"
                          data-toggle="modal"
                          data-target="#myModal"
                        >
                          <span className="pr-2">
                            <FaSignOutAlt />
                          </span>
                          SignOut
                        </Link>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
