import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import '../Styles/Signin.css';
import SigninPage from '../Pages/SigninPage';
import {IoIosCloseCircle } from "@react-icons/all-files/io/IoIosCloseCircle";

import {Link} from 'react-router-dom';


export default function ForgotPassword() {

  return (
    <>
      <div className="row main-banner">
            <div className="col-md-6 top-p">
                <div className="login-banner"></div>
                <div className="Logo">
                    <img src="\SqillUPImages\Logo Rev A-02.png"alt=""/> 
                </div>
            </div>
              <div className="col-md-6">
                <SigninPage/>
              <div className='overlay'></div>
            </div>
        </div>
        <div className='forgot-card'>
           <div className='float-right'>
              <div className='close'>
                <Link to='/'>
                <IoIosCloseCircle/>
                </Link>
              </div>
            </div>
              <p className='p-t'>
                Forgot your username and password You can recover it through a parent account.
              </p>       
        </div>        
    </>  
  )
}

