import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Curriculum.css";

import { RiDownload2Fill } from "@react-icons/all-files/ri/RiDownload2Fill";

import { homeViewAll, studentCurriculum } from "../Services";
import { base } from "../Constants";

function Curriculum() {
  const [requestData, setRequestData] = useState("");
  const [allSubject, setAllSubject] = useState([]);
  const [curriculum, setCurriculum] = useState([]);

  useEffect(() => {
    homeViewAll().then((data) => {
      setAllSubject(data);
    });
    studentCurriculum(requestData).then((data) => {
      setCurriculum(data);
    });
  }, []);

  const handleSelectChange = (event) => {
    setRequestData(event.target.value);
    studentCurriculum(event.target.value).then((data) => {
      setCurriculum(data);
    });
  };

  return (
    <>
      <div className="curriculum-wrapper">
        <div className="row  ">
          <div className="col-md-12 d-flex">
            <div className="col-md-6">
              <div className="Left-curriculum">
                <h5>Curriculum</h5>
              </div>
            </div>
            <div className="col-md-6">
              <div className="Right-curriculum d-md-flex justify-content-md-end">
                <p className="curriculum_Hide">Download Ks3 syllabus for AQA</p>
                <button>
                  Download syllabus
                  <span>
                    <RiDownload2Fill />
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="curriculum-dropdown">
              <select
                className="dropdown-toggle "
                name="subject_id"
                onChange={handleSelectChange}
              >
                <option value="">Select Subject</option>
                {allSubject.length &&
                  allSubject.map((data, index) => (
                    <option value={data.id}>{data.name}</option>
                  ))}
                {/* <option value="chemistry">Chemistry</option>
                <option value="physics">Physics</option>
                <option value="biology">Biology</option>
                <option value="maths">Maths</option> */}
              </select>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-8 row">
              <div className="col-md-3">
                <div className="Curriculum-List">
                  <ol className="Revision-Decimal">
                    {curriculum.length > 0 &&
                      curriculum.map((data, index) => (
                        <li>{data?.lesson[0]?.name}</li>
                      ))}
                  </ol>
                </div>
              </div>
              <div className="col-md-9">
                <div className="Curriculum-table">
                  <table className="table">
                    <thead>
                      <th>Lesson Code</th>
                      <th>Lesson name</th>
                      <th>Description</th>
                    </thead>

                    <tbody>
                      {curriculum.length > 0 &&
                        curriculum.map((data) => (
                          <tr>
                            <td className="Revision-title">
                              {data?.lesson[0]?.number}
                            </td>
                            <td>{data?.lesson[0]?.name}</td>
                            {/* Dynamic Html */}
                            <td>
                              {" "}
                              <div
                                id="description"
                                dangerouslySetInnerHTML={{
                                  __html: data?.lesson[0]?.description,
                                }}
                              ></div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="Curriculum-right-table">
                <table className="table">
                  <thead>
                    <th>Selftest</th>
                    <th>Video</th>
                    <th>Short notes</th>
                  </thead>
                  <tbody>
                    {curriculum.length > 0 &&
                      curriculum.map((data) => (
                        <tr>
                          <td>
                            <img src="\SqillUPImages\Tickmark.svg" alt="" />
                          </td>
                          <td>
                            <img src="\SqillUPImages\Tickmark.svg" alt="" />
                          </td>
                          <td>
                            {" "}
                            <a
                              href={`${base}${data.lesson[0]?.presentation}`}
                              target="_blank"
                            >
                              <img src="\SqillUPImages\Path 5287.svg" alt="" />
                            </a>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="Curriculum-footer">
          <p>
            <span>Note:</span> This is for guidance only
          </p>
        </div>
      </div>
    </>
  );
}

export default Curriculum;
