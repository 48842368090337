import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Revision.css";

import { homeViewAll, studentRevision, getAllPastPaper } from "../Services";

import { FaMinus } from "@react-icons/all-files/fa/FaMinus";
import { useNavigate } from "react-router-dom";
import { base } from "../Constants";

function Revision() {
  const [requestData, setRequestData] = useState("");
  const [allSubject, setAllSubject] = useState([]);
  const [revision, setRevision] = useState([]);
  const [selectNav, setSelectNav] = useState("1");

  useEffect(() => {
    homeViewAll().then((data) => {
      setAllSubject(data);
    });
    studentRevision(requestData).then((data) => {
      setRevision(data);
    });
  }, []);

  const handleSelectChange = (event) => {
    setRequestData(event.target.value);
    studentRevision(event.target.value).then((data) => {
      setRevision(data);
    });
  };

  let navigate = useNavigate();
  const handelRedoSubmit = (lesson_id) => {
    let lessonInfo = {
      lessonid: lesson_id,
    };

    navigate("/selftest", { state: { lessonId: lessonInfo } });
  };

  const handleSelect = (idx) => {
    if (idx === "2") {
      let request = {
        subject_id: requestData,
        exam_board_id: "",
        year: "",
      };
      getAllPastPaper(request).then((data) => {
        setRevision(data);
      });
    } else {
      studentRevision(requestData).then((data) => {
        setRevision(data);
      });
    }
    setSelectNav(idx);
  };

  const handleClickOnLesson = (idx) => {
    let params = [];
    let data = revision;
    params.push(data[idx]);
    setRevision(params);
  };

  return (
    <div>
      <div className="Revision-wrapper">
        <h5 className="mb-3">Revision</h5>
        <div className="row mb-3">
          <div className="col-md-2">
            <div className="Revision-right1">
              <select
                className="dropdown-toggle"
                name="subject_id"
                onChange={handleSelectChange}
              >
                <option value="">Select-Subject</option>
                {allSubject.length &&
                  allSubject.map((data, index) => (
                    <option value={data.id}>{data.name}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-6"></div>
          <div className="mar-right col-md-4 text-right">
            {/* <Link to="/revision"> */}{" "}
            <button
              className="revision-buttons btn  btn-sm"
              id={selectNav === "1" && "selftest_button"}
              onClick={() => handleSelect("1")}
            >
              Self test
            </button>
           
            <button
              className="revision-buttons btn  btn-sm"
              id={selectNav === "2" && "selftest_button"}
              onClick={() => handleSelect("2")}
            >
              Pastpaper
            </button>
           
            <button
              className=" revision-buttons btn  btn-sm"
              id={selectNav === "3" && "selftest_button"}
              onClick={() => handleSelect("3")}
            >
              Worksheet
            </button>
          
          </div>
        </div>
        <div className="row">
          {(selectNav === "1" || selectNav === "3") && (
            <div className="row col-md-8">
              <div className="col-md-3">
                <div className="Revision-List">
                  <ol className="Revision-Decimal">
                    {revision.length > 0 &&
                      revision.map((data, index) => (
                        <li onClick={() => handleClickOnLesson(index)} 
                        style={{backgroundColor:index===0?"#6ECACD":"",color:index===0?"#fff":""}}>
                          {data?.lesson?.name}
                        </li>
                      ))}
                  </ol>
                </div>
              </div>
              <div className="col-md-9">
                <div className="Revision-table">
                  <table className="table">
                    <thead>
                      <th>Lesson Code</th>
                      <th>Lesson name</th>
                      <th>Description</th>
                    </thead>
                    {revision.length > 0 &&
                      revision.map((data) => (
                        <tbody>
                          <tr>
                            <td className="Revision-title">
                              {data?.lesson?.number}
                            </td>
                            <td>{data?.lesson?.name}</td>
                            {/* Dynamic Html */}
                            <td>
                              {" "}
                              <div
                                id="description"
                                dangerouslySetInnerHTML={{
                                  __html: data?.lesson?.description,
                                }}
                              ></div>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                  </table>
                </div>
              </div>
            </div>
          )}
          {selectNav === "2" && (
            <div className="col-md-8">
              <div className="Revision-table">
                <table className="table">
                  <thead>
                    <th>Chapter name</th>
                  </thead>
                  {revision.length > 0 &&
                    revision.map((data, index) => (
                      <tbody>
                        <tr>
                          <td>
                            {index + 1} . {data?.chapter?.name}
                          </td>
                        </tr>
                      </tbody>
                    ))}
                </table>
              </div>
            </div>
          )}
          <div className="col-md-4">
            <div className="Revision-table-right-data row ">
              <div className="col-md-3">
                <table className="table mini-tab">
                  <thead>
                    <th>Com</th>
                  </thead>
                  <tbody>
                    {revision.length > 0 &&
                      revision.map((data) =>
                        data.lesson?.ongoing_lesson?.status === "complete" ? (
                          <tr>
                            <td>
                              <img src="\SqillUPImages\Tickmark.svg" alt="" />
                            </td>
                          </tr>
                        ) : (
                          <tr>
                            <td>
                              <img src="\SqillUPImages\path 3052.svg" alt="" />
                            </td>
                          </tr>
                        )
                      )}
                  </tbody>
                </table>
              </div>
              <div className="col-md-3">
                <table className="table mini-tab">
                  <thead>
                    <th>Redo</th>
                  </thead>
                  <tbody>
                    {revision.length > 0 &&
                      revision.map((data) =>
                        selectNav === "1" ? (
                          <tr
                            onClick={() => handelRedoSubmit(data.lesson_id)}
                            style={{ cursor: "pointer" }}
                          >
                            <td>Start</td>
                          </tr>
                        ) : selectNav === "3" ? (
                          <tr style={{ cursor: "pointer" }}>
                            <td>
                              <a href={`https://google.com`} target="_blank">
                                <button className="btn btn-primary">
                                  View
                                </button>
                              </a>
                            </td>
                          </tr>
                        ) : (
                          <tr>
                            <td>--</td>
                          </tr>
                        )
                      )}
                  </tbody>
                </table>
              </div>
              <div className="col-md-3">
                <table className="table mini-tab">
                  <thead>
                    <th>Score</th>
                  </thead>
                  <tbody>
                    {revision.length > 0 &&
                      revision.map((data) =>
                        data.lesson?.lesson_score ? (
                          <tr>
                            <td>{data.lesson?.lesson_score?.score}</td>
                          </tr>
                        ) : (
                          <tr>
                            <td>
                              {" "}
                              <FaMinus />
                            </td>
                          </tr>
                        )
                      )}
                  </tbody>
                </table>
              </div>
              <div className="col-md-3">
                <div className="Revision-Icon-Table">
                  <table className="table mini-tab">
                    <thead>
                      <th>Notes</th>
                    </thead>
                    <tbody>
                      {revision.length > 0 &&
                        revision.map((data) => (
                          <tr>
                            <td>
                              <a
                                href={`${base}${data.presentation}`}
                                target="_blank"
                              >
                                <img
                                  src="\SqillUPImages\Path 5287.svg"
                                  alt=""
                                />
                              </a>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Revision;
