import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '../Styles/Revision.css';

import {Link} from 'react-router-dom';

import { FaMinus } from "@react-icons/all-files/fa/FaMinus";
function Revision_Worksheet() {
  return (
    <div>
      <div className="Revision-wrapper">
        <h5 className='mb-3'>Revision</h5>
        <div className='row mb-3'>
          <div className='col-md-2'>
            <div className="Revision-right1">
              <select className="dropdown-toggle " >
                <option value="chemistry">Chemistry</option>
                <option value="physics">Physics</option>
                <option value="biology">Biology</option>
                <option value="maths">Maths</option>
              </select>
            </div>
          </div>
          <div className='mar-right col-md-3 text-right'>
           <Link to="/revision"> <button className='revision-buttons btn  btn-sm'id="worksheet">Selftest</button></Link>
            <Link to="/rpastpaper"><button className='revision-buttons btn  btn-sm'id="pastpaper_button">Pastpaper</button></Link>
            <Link to="/rworksheet"><button className=' revision-buttons btn  btn-sm'id="selftest_button">Worksheet</button></Link>
          </div>
        </div>
        <div className='row'>
          <div className='row col-md-8'>
            <div className='col-md-3'>
              <div className='Revision-List'>
                <ol className='Revision-Decimal'>
                  <li className='active'>State of matter</li>
                  <li>Melting and freezing</li>
                  <li>Pure and impure</li>
                  <li>Particles</li>
                  <li>Organic Chemistry</li>
                  <li>Oxidation</li>
                </ol>
              </div>

            </div>
            <div className='col-md-9'>
              <div className='Revision-table'>
                <table className='table'>
                  <thead>
                    <th>Lesson Code</th>
                    <th>Lesson name</th>
                    <th>Description</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='Revision-title'>1.1</td>
                      <td>State of matter 1</td>
                      <td>Properties and behaviour of state of matter.</td>
                    </tr>
                    <tr>
                      <td className='Revision-title'>1.2</td>
                      <td>Melting and freezing</td>
                      <td>Particles explanations for properties.</td>
                    </tr>
                    <tr>
                      <td className='Revision-title'>1.2</td>
                      <td>Particles and there behaviour</td>
                      <td>Changes of state and energy.</td>
                    </tr>
                    <tr>
                      <td className='Revision-title'>1.2</td>
                      <td>Gas pressure</td>
                      <td>Melting and boiling Point.</td>
                    </tr>

                    <tr>
                      <td className='Revision-title'>1.2</td>
                      <td>Diffusion</td>
                      <td>How do particles create pressure in gas.</td>
                    </tr>

                    <tr>
                      <td className='Revision-title'>1.2</td>
                      <td>Chemical changes</td>
                      <td>Example and particle explanations.</td>
                    </tr>

                    <tr>
                      <td className='Revision-title'>1.2</td>
                      <td>Extension</td>
                      <td>How do particles create pressure in gas.</td>
                    </tr>

                    <tr>
                      <td className='Revision-title'>1.2</td>
                      <td>State of matter 2</td>
                      <td>Difference between chemical and physical.</td>
                    </tr>

                    <tr>
                      <td className='Revision-title'>1.2</td>
                      <td>Gas pressure 2</td>
                      <td>How do particles create pressure in gas.</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='Revision-table-right-data row '>
              <div className='col-md-3'>

                <table className='table mini-tab'>
                  <thead><th>Com</th></thead>
                  <tbody>
                    <tr>
                      <td><img src="\SqillUPImages\Tickmark.svg" alt="" /></td>
                    </tr>
                    <tr>
                      <td><img src="\SqillUPImages\Tickmark.svg" alt="" /></td>
                    </tr>
                    <tr>
                      <td><img src="\SqillUPImages\Tickmark.svg" alt="" /></td>
                    </tr>
                    <tr>
                      <td><img src="\SqillUPImages\Tickmark.svg" alt="" /></td>
                    </tr>
                    <tr>
                      <td><img src="\SqillUPImages\Tickmark.svg" alt="" /></td>
                    </tr>
                    <tr>
                    <td><img src="\SqillUPImages\Tickmark.svg" alt="" /></td>
                    </tr>
                    <tr>
                    <td><img src="\SqillUPImages\Tickmark.svg" alt="" /></td>
                    </tr>

                  </tbody>
                </table>
              </div>

              <div className='col-md-3'>
                <div className='Revision-worksheet-Icon-Table'>
                <table className='table mini-tab'>
                  <thead>
                    <th>
                      Redo
                    </th>
                  </thead>
                  <tbody>
                    <tr>
                    <td><span><FaMinus /></span></td>
                    </tr>
                    <tr>
                    <td><span><FaMinus /></span></td>
                    </tr>
                    <tr>
                    <td><span><FaMinus /></span></td>
                    </tr>
                    <tr>
                    <td><span><FaMinus /></span></td>
                    </tr>
                    <tr>
                    <td><span><FaMinus /></span></td>
                    </tr>

                    <tr>
                      <td><span><FaMinus /></span></td>
                    </tr>
                    <tr>
                      <td><span><FaMinus /></span></td>
                    </tr>

                  </tbody>
                </table>
              </div>
              </div>
              <div className='col-md-3'>

                <table className='table mini-tab'>
                  <thead>
                    <th>
                      Score
                    </th>
                  </thead>
                  <tbody>
                    <tr>
                      <td >15</td>
                    </tr>
                    <tr>
                      <td>09</td>
                    </tr>
                    <tr>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td>20</td>
                    </tr>
                    <tr>
                      <td>18</td>
                    </tr>

                    <tr>
                      <td>20</td>
                    </tr>
                    <tr>
                      <td>18</td>
                    </tr>

                  </tbody>
                </table>
              </div>

              <div className='col-md-3'>
                <div className='Revision-Icon-Table'>
                  <table className='table mini-tab'>
                    <thead>
                      <th>
                        Notes
                      </th>
                    </thead>

                    <tbody>
                      <tr>
                        <td><img src="\SqillUPImages\Path 5287.svg" alt="" /></td>
                      </tr>
                      <tr>
                        <td><img src="\SqillUPImages\Path 5287.svg" alt="" /></td>
                      </tr>
                      <tr>
                        <td><img src="\SqillUPImages\Path 5287.svg" alt="" /></td>
                      </tr>
                      <tr>
                        <td><img src="\SqillUPImages\Path 5287.svg" alt="" /></td>
                      </tr>
                      <tr>
                        <td><img src="\SqillUPImages\Path 5287.svg" alt="" /></td>
                      </tr>
                      <tr>
                        <td><img src="\SqillUPImages\Path 5287.svg" alt="" /></td>
                      </tr>
                      <tr>
                        <td><img src="\SqillUPImages\Path 5287.svg" alt="" /></td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>

  )
}

export default Revision_Worksheet