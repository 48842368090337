import React from 'react';
import Topbar from '../Pages/Topbar.js';
import Leftbar from '../Pages/Leftbar.js';
import '../Styles/Home.css';


export default function Home() {
  
  return (
    <div>     
      <div><Topbar/></div>
      <div><Leftbar/></div>
    </div>  
  )
}
