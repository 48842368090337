import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Courses.css";
import SelfTest from "./SelfTest";
import QandA from "./QandA";
import { useNavigate } from "react-router-dom";

import { Link } from "react-router-dom";

function Tabs(lessonInfo) {
  const [lessonParams, setlessonParams] = useState(lessonInfo);

  let navigate = useNavigate();

  const handleSelfTest = () => {
    navigate("/selftest", { state: { lessonId: lessonInfo } });
  };

  return (
    <div>
      <div className="tab_container">
        <input id="tab1" type="radio" name="tabs" checked />
        <label for="tab1">
          <span>
            <img src="\SqillUPImages\view.svg" alt=" " />
            &nbsp;&nbsp;OverView
          </span>
        </label>

        <input id="tab2" type="radio" name="tabs" />
        <label for="tab2">
          <span>
            <img src="\SqillUPImages\question.svg" alt=" " />
            &nbsp;&nbsp;Q&A
          </span>
        </label>
        <input id="tab3" type="radio" name="tabs" />
        <label for="tab3" className="selftest_linktag">
          {/* <Link to="/selftest"> */}
          <span onClick={handleSelfTest}>
            <img src="\SqillUPImages\test.svg" alt=" " />
            &nbsp;&nbsp;Self Test
          </span>
          {/* </Link> */}
        </label>

        <input id="tab4" type="radio" name="tabs" />
        <label for="tab4">
          <span>
            <img src="\SqillUPImages\google-docs.svg" alt=" " />
            &nbsp;&nbsp;WorkSheet
          </span>
        </label>

        <input id="tab5" type="radio" name="tabs" />
        <label for="tab5" className="selftest_linktag">
          <Link to="/chapterpaper">
            <span>
              <img src="\SqillUPImages\copy (1).svg" alt=" " />
              &nbsp;&nbsp;Chapter Paper
            </span>
          </Link>
        </label>

        <section id="content1" className="tab-content">
          <h5>Particles</h5>
          <p>
            Tortor posuere ac ut consequat semper. Cursus vitae congue mauris
            rhoncus. Leo a diam sollicitudin tempor id. Iaculis at erat
            pellentesque adipiscing commodo elit at imperdiet. Quam nulla
            porttitor massa id neque aliquam. Malesuada fames ac turpis egestas
            integer eget aliquet nibh. Donec pretium vulputate sapien nec
            sagittis aliquam malesuada bibendum arcu. Interdum posuere lorem
            ipsum dolor sit. In vitae turpis massa sed elementum tempus egestas
            sed. Ultricies tristique nulla aliquet enim tortor at auctor urna
            nunc.
          </p>
          <p>
            Feugiat in fermentum posuere urna nec tincidunt praesent semper
            feugiat. Sagittis id consectetur purus ut faucibus pulvinar
            elementum integer. Quis lectus nulla at volutpat diam ut.
          </p>
          <h5>Description of video</h5>
          <p>
            Urna cursus eget nunc scelerisque viverra mauris. Mauris a diam
            maecenas sed. Sodales ut etiam sit amet nisl purus in mollis.
            Commodo sed egestas egestas fringilla phasellus. Molestie at
            elementum eu facilisis sed odio morbi quis. Euismod in pellentesque
            massa placerat duis. Vulputate dignissim suspendisse in est ante in
            nibh. Eu scelerisque felis imperdiet proin fermentum. Rhoncus mattis
            rhoncus urna neque viverra. Aliquet nec ullamcorper sit amet risus
            nullam.
          </p>

          <div className="col-md-8 ">
            <div className="tab-Author d-flex">
              <div className="col-md-4">
                <h5>Author Details</h5>
                <span>Sqillup</span>
              </div>
              <div className="col-md-4">
                <h5>Published Date</h5>
                <span>20 March 2021</span>
              </div>
            </div>
          </div>
        </section>

        <section id="content2" className="tab-content">
          <QandA lessonid={lessonInfo} />
        </section>

        <section id="content3" className="tab-content">
          <SelfTest lessonInfo={lessonParams} />
        </section>

        <section id="content4" className="tab-content">
          Data Second Heading 4
        </section>

        <section id="content5" className="tab-content">
          <Link to="/chapterpaper">
            <span>
              <img src="\SqillUPImages\copy (1).svg" alt=" " />
              &nbsp;Chapter Paper
            </span>
          </Link>
        </section>
      </div>
    </div>
  );
}

export default Tabs;
