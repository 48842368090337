import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Signin.css";

import { AiOutlineInfoCircle } from "@react-icons/all-files/ai/AiOutlineInfoCircle";

import { myProfile } from "../Services";

export default function MyProfile() {
  //let [errorMessage, seterrorMessage] = useState("");
  //   let [data, setData] = useState("");
  const [userName, setUserName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateofbirth, setDateofBirth] = useState("");
  const [schoolname, setSchoolName] = useState("");
  const [email, setEmail] = useState("");
  const [examboard, setExamboard] = useState("");
  const [keystatge, setKeyStatge] = useState("");
  const [studentClass, setStudentClass] = useState("");
  const [packagetype, setPackageType] = useState("");
  const [stuImage, setStuImage] = useState("");
  // greetUser();
  useEffect(() => {
    myProfile().then((data) => {
      setUserName(data.user_name);
      setFirstName(data.first_name);
      setLastName(data.last_name);
      setDateofBirth(data?.student_detail?.dob);
      setSchoolName(data?.student_detail?.school_name);
      setEmail(data.email);
      setExamboard(data?.student_detail?.exam_board?.name);
      setKeyStatge(data?.student_detail?.key_stage?.name);
      setStudentClass(data?.student_detail?.grade.name);
      setPackageType(data?.plans[0]?.name);
      setStuImage(data);
    });
    //   .catch((error) => {
    //     seterrorMessage(error?.response?.data?.message);
    //   });
  }, []);

  return (
    <>
      <div className="student-account">
        <h5>Student Account Details</h5>
        <div className="mt-4 bg-white">
          <div className="Basic">
            <h6>
              <span className="pl-2 pr-2">
                <AiOutlineInfoCircle />
              </span>
              Basic Details
            </h6>
          </div>
          <form>
            <div className="row col-md-12 p-4 form-data d-flex">
              {/* First Form */}
              <div className="col-md-3 ">
                <div className="row m-0">
                  <div className="col-md-3">
                    <div className="user-Image">
                      <img src="./SqillUPImages/user_profile@2x.png" alt="" />
                      {/* <img src={stuImage.image} alt="" /> */}
                    </div>
                  </div>
                  <div className="pt-4">
                    <div className="form-group">
                      <label>Enter username</label>
                      <input
                        type="text"
                        id="uname"
                        name="Enter username"
                        value={userName}
                        placeholder=""
                        className="form-control"
                      ></input>
                    </div>
                    <div className="form-group">
                      <label>Password</label>
                      <input
                        type="password"
                        id="pass"
                        name="password"
                        value="********"
                        placeholder=""
                        className="form-control"
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-1 vertical-line"></div>
              <div className="col-md-8 row">
                <div className="padding-top"></div>
                {/* Second Form */}
                <div className="col-md-4">
                  <div className="form-group">
                    <label>First Name</label>
                    <input
                      type="text"
                      id="fname"
                      name="First Name"
                      value={firstName}
                      placeholder="Filled"
                      className="form-control"
                    ></input>
                  </div>
                  <div className="form-group">
                    <label>Date of Birth</label>
                    <input
                      type="text"
                      id="dob"
                      name="Date of Birth"
                      value={dateofbirth}
                      placeholder="15/04/2015"
                      className="form-control"
                    ></input>
                  </div>
                  <div className="form-group">
                    <label>Key stage</label>
                    <input
                      type="text"
                      id="keysta"
                      value={keystatge}
                      name="Key stage"
                      placeholder="KS 1"
                      className="form-control"
                    ></input>
                  </div>
                </div>
                {/* Third Form */}
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Last Name</label>
                    <input
                      type="text"
                      id="lname"
                      name="text"
                      value={lastName}
                      placeholder="Filled"
                      className="form-control"
                    ></input>
                  </div>
                  <div className="form-group">
                    <label>Exam Board</label>
                    <input
                      type="text"
                      id="exam_board"
                      value={examboard}
                      name="Exam Board"
                      placeholder="Edexel"
                      className="form-control"
                    ></input>
                  </div>
                  <div className="form-group">
                    <label>Class</label>
                    <input
                      type="text"
                      id="studentclass"
                      name="Class"
                      value={studentClass}
                      placeholder="Class 3"
                      className="form-control"
                    ></input>
                  </div>
                </div>
                {/* Fourth Form */}
                <div className="col-md-4">
                  <div className="form-group">
                    <label>School Name</label>
                    <input
                      type="text"
                      id="school_name"
                      name="text"
                      value={schoolname}
                      placeholder="St Pauls boys school"
                      className="form-control"
                    ></input>
                  </div>
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="text"
                      id="email"
                      name="Email"
                      value={email}
                      placeholder="lucifer@123gmail.com"
                      className="form-control"
                    ></input>
                  </div>
                  <div className="form-group">
                    <label>Package</label>
                    <input
                      type="text"
                      id="package"
                      value={packagetype}
                      name="Package"
                      placeholder="Pro"
                      className="form-control"
                    ></input>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
