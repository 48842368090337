import React from 'react';
import Help from '../Dashboard/Help';
import 'bootstrap/dist/css/bootstrap.css';
import '../Styles/Help.css';

function HelpMessage() {
  return (
   <>
    <div>
        <Help/>
        <div className="overlay"></div>
        <div className="Help_card">
            <img src="/SqillUPImages/Check_green_circle.png" alt="" />
            <h6>Thanks For Reaching Out.</h6>
            <p>Someone from our side will get back to you Soon</p>
        </div>
    </div>
   </>
  )
}

export default HelpMessage