import "./App.css";
import SignIn from "./Components/SignIn";
import ForgotPassword from "./Components/ForgotPassword";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
//Leftbar components
import Home from "./Dashboard/Home";
import Challenge from "./Dashboard/Challenge";
import ContactUs from "./Dashboard/ContactUs";
import Courses from "./Dashboard/Courses";
import Curriculum from "./Dashboard/Curriculum";
import Help from "./Dashboard/Help";
import PastPaper from "./Dashboard/PastPaper";
import Revision from "./Dashboard/Revision";
import Swap from "./Dashboard/Swap";
import FAQ from "./Dashboard/FAQ";
import MockTest from "./Dashboard/MockTest";
import HomeSection from "./Pages/HomeSection";
import ViewAll from "./Pages/ViewAll";
import ViewAllScore from "./Pages/ViewAllScore";
import HelpMessage from "./Pages/HelpMessage";

// Topbar Components
import MyProfile from "./InsideComponents/MyProfile";
import Signout from "./InsideComponents/Signout";
import Badges from "./InsideComponents/Badges";
import Notification from "./InsideComponents/Notification";
import Rewards from "./InsideComponents/Rewards";
// Cousrses Components
import Completed from "./Courses/Completed";
import OnGoing from "./Courses/OnGoing";
import Wishlist from "./Courses/Wishlist";
import ParticularSubject from "./Courses/ParticularSubject";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import PostQuestion from "./Courses/PostQuestion";
import SelfTest from "./Courses/SelfTest";
// import SelfTest1 from "./Courses/SelfTest1";
// import SelfTest2 from "./Courses/SelfTest2";
// import SelfTestQuestion2 from "./Courses/SelfTestQuestion2";
// import SelfTestQuestion2_1 from "./Courses/SelfTestQuestion2_1";
// import SelfTestQuestion2_2 from "./Courses/SelfTestQuestion2_2";
// import SelfTestQuestion3 from "./Courses/SelfTestQuestion3";
// import SelfTestQuestion3_1 from "./Courses/SelfTestQuestion3_1";
// import SelfTestQuestion3_2 from "./Courses/SelfTestQuestion3_2";
// import SelfTestQuestion10 from "./Courses/SelfTestQuestion10";
// import SelfTestQuestion10_1 from "./Courses/SelfTestQuestion10_1";
// import SelfTestQuestion10_2 from "./Courses/SelfTestQuestion10_2";
import ChapterPaper from "./Courses/ChapterPaper";
import Pastpaperview from "./Courses/Pastpaperview";
import SelftestSubmitScreen from "./Courses/SelftestSubmitScreen";
import Submit from "./Courses/Submit";
// Challenges Components
import BPHO from "./Challenges/BPHO";
import RSOC from "./Challenges/RSOC";
import UKMT from "./Challenges/UKMT";
// Revision Components
import Revision_Worksheet from "./Revision/Revision_Worksheet";
import Revision_Pastpaper from "./Revision/Revision_Pastpaper";
// Curriculum Components
import MeetingandFreezing from "./Curriculum/MeetingandFreezing";


const NavRoute = ({ component: Component, ...rest }) => (
  <div>
    <Home />
    <Component />
  </div>
);

const isLoggedIn = () => {
  return sessionStorage.getItem("sqlupToken") ? true : false;
};
function App() {
  return (
    <div className="App">
      {/* <ToastContainer /> */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route path="/home" element={<Home />} />
          <Route
            path="/challenge"
            element={<NavRoute component={Challenge} />}
          />
          <Route path="/contact" element={<NavRoute component={ContactUs} />} />
          <Route path="/courses" element={<NavRoute component={Courses} />} />
          <Route
            path="/curriculum"
            element={<NavRoute component={Curriculum} />}
          />
          <Route path="/help" element={<NavRoute component={Help} />} />
          <Route
            path="/helpmessage"
            element={<NavRoute component={HelpMessage} />}
          />
          <Route
            path="/pastpaper"
            element={<NavRoute component={PastPaper} />}
          />
          <Route path="/revision" element={<NavRoute component={Revision} />} />
          <Route path="/swap" element={<NavRoute component={Swap} />} />
          <Route
            path="/notification"
            element={<NavRoute component={Notification} />}
          />
          <Route path="/mocktest" element={<NavRoute component={MockTest} />} />
          <Route path="/faq" element={<NavRoute component={FAQ} />} />
          <Route
            path="/myprofile"
            element={<NavRoute component={MyProfile} />}
          />
          <Route path="/signout" element={<NavRoute component={Signout} />} />
          <Route path="/badges" element={<NavRoute component={Badges} />} />
          <Route path="/rewards" element={<NavRoute component={Rewards} />} />
          <Route
            path="/homesection"
            element={<NavRoute component={HomeSection} />}
          />
          {/* <Route
            path="/homesection"
            element={
              isLoggedIn() ? (
                <NavRoute component={HomeSection} />
              ) : (
                <Navigate to="/" />
              )
            }
          /> */}
          <Route path="/viewall" element={<NavRoute component={ViewAll} />} />
          <Route
            path="/viewallscore"
            element={<NavRoute component={ViewAllScore} />}
          />
          <Route
            path="/completed"
            element={<NavRoute component={Completed} />}
          />
          <Route path="/ongoing" element={<NavRoute component={OnGoing} />} />
          <Route path="/wishlist" element={<NavRoute component={Wishlist} />} />
          <Route
            path="/particularsubject/:id/:chapter"
            element={<NavRoute component={ParticularSubject} />}
          />
          <Route
            path="/postquestion"
            element={<NavRoute component={PostQuestion} />}
          />
          <Route path="/selftest" element={<SelfTest />} />
          {/* <Route path="/selftest1" element={<SelfTest1 />} />
          <Route path="/selftest2" element={<SelfTest2 />} />
          <Route path="/selftestquestion2" element={<SelfTestQuestion2 />} />
          <Route
            path="/selftestquestion2_1"
            element={<SelfTestQuestion2_1 />}
          />
          <Route
            path="/selftestquestion2_2"
            element={<SelfTestQuestion2_2 />}
          />
          <Route path="/selftestquestion3" element={<SelfTestQuestion3 />} />
          <Route
            path="/selftestquestion3_1"
            element={<SelfTestQuestion3_1 />}
          />
          <Route
            path="/selftestquestion3_2"
            element={<SelfTestQuestion3_2 />}
          />
          <Route path="/selftestquestion10" element={<SelfTestQuestion10 />} />
          <Route
            path="/selftestquestion10_1"
            element={<SelfTestQuestion10_1 />}
          />
          <Route
            path="/selftestquestion10_2"
            element={<SelfTestQuestion10_2 />}
          /> */}
          <Route
            path="/selftestsubmitscreen"
            element={<SelftestSubmitScreen />}
          />
          <Route path="/submit" element={<Submit/>} />
          <Route path="/chapterpaper" element={<ChapterPaper />} />
          <Route path="/pastpaperview" element={<Pastpaperview />} />
          <Route path="/bpho" element={<NavRoute component={BPHO} />} />
          <Route path="/rsoc" element={<NavRoute component={RSOC} />} />
          <Route path="/ukmt" element={<NavRoute component={UKMT} />} />
          <Route
            path="/rworksheet"
            element={<NavRoute component={Revision_Worksheet} />}
          />
          <Route
            path="/rpastpaper"
            element={<NavRoute component={Revision_Pastpaper} />}
          />
          <Route
            path="/meetingandfreezing"
            element={<NavRoute component={MeetingandFreezing} />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;
