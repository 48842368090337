import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Leftbar.css";
import { Link } from "react-router-dom";

export default function Leftbar() {
  return (
    <>
      <div className="aside">
        <div className="Sidebar">
          <div className="navbar-brand">
            <img src="\SqillUPImages\Logo Rev A-02.png" alt="" />
          </div>
          <div className="Leftbar-Icons">
            <Link
              to="/homesection"
              data-text="Home"
              className="nav-link"
              id="home"
            >
              <img src="\SqillUPImages\Home.svg" alt="" />
            </Link>
            <Link
              to="/courses"
              data-text="Courses"
              className="nav-link"
              id="courses"
            >
              <img src="\SqillUPImages\Courses.svg" alt="" />
            </Link>
            <Link
              to="/pastpaper"
              data-text="PastPaper"
              className="nav-link"
              id="pastpaper"
            >
              <img src="\SqillUPImages\Pastpaper.svg" alt="" />
            </Link>
            {/* <Link to="/challenge" data-text="Challenge" className="nav-link" id="challenge">
          <img src="\SqillUPImages\Challenge.svg"alt=""/>
          </Link> */}
            <Link
              to="/revision"
              data-text="Revision"
              className="nav-link"
              id="revision"
            >
              <img src="\SqillUPImages\Revision.svg" alt="" />
            </Link>
            <Link
              to="/curriculum"
              data-text="Curriculum"
              className="nav-link"
              id="curriculum"
            >
              <img src="\SqillUPImages\Curriculum.svg" alt="" />
            </Link>
            <Link
              to="/mocktest"
              data-text="MockTest"
              className="nav-link"
              id="curriculum"
            >
              <span>
                <img src="\SqillUPImages\Mocktest.svg" alt="" />
              </span>
            </Link>

            <div className="Sidebar-bottom">
              {/* <Link to="/swap" data-text="Swap" className="nav-link" id="swap">
            <span><img src="\SqillUPImages\swap.svg"alt=""/></span>
            </Link>
            <Link to="/help" data-text="Help" className="nav-link" id="help">
            <span><img src="\SqillUPImages\Help.svg"alt=""/></span>
            </Link>
            <Link to="/faq" data-text="FAQ" className="nav-link" id="faq">
            <span><img src="\SqillUPImages\FAQ.svg"alt=""/></span>
            </Link> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
