import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Courses.css";
import Tabs from "./Tabs";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import { useParams } from "react-router-dom";
import { GenericGet, studentGlossary } from "../Services";
import Loader from "../Helpers/Loader";

function ParticularSubject() {
  const [lesson, setLesson] = useState(null);
  const [chapterId, setchapterId] = useState(null);
  const [subjectId, setsubjectId] = useState(null);
  const [chapters, setChapters] = useState(null);
  const [isUrlExists, setIsUrlExists] = useState(null);
  const { id } = useParams();
  const { chapter } = useParams();

  const [glossarydata, setGlossaryData] = useState([]);

  useEffect(() => {
    studentGlossary().then((data) => {
      setGlossaryData(data);
    });
  }, []);

  useEffect(() => {
    GenericGet(null, "viewLesson?id=" + id).then((data) => {
      setLesson(data[0]);
      if (data[0].video_url != "" && data[0].video_url != null) {
        setIsUrlExists(true);
      }
      setsubjectId(data[0].subject_id);
      setchapterId(data[0].chapter_id);
    });
  }, []);

  const handelPlay = (obj) => {
    debugger;
    GenericGet(null, "viewLesson?id=" + obj.id).then((data) => {
      setLesson(data[0]);
      if (data[0].video_url != "" && data[0].video_url != null) {
        setIsUrlExists(true);
      }
      setsubjectId(data[0].subject_id);
      setchapterId(data[0].chapter_id);
    });
  };

  useEffect(() => {
    GenericGet(null, "viewLesson?chapter_id=" + chapter).then((data) => {
      setChapters(data);
    });
  }, []);

  return (
    <div>
      <div className="particular-content-wrapper">
        <div className="row">
          <div className="particular-content-align">
            <div className="col-md-8">
              <div className="player-wrapper">
                {isUrlExists ? (
                  <ReactPlayer
                    url={lesson != null ? lesson.video_url : ""}
                    className="react-player"
                    style={{ position: "relative", right: "0%" }}
                    width={900}
                    height={400}
                    playing
                    controls
                  />
                ) : (
                  <b><Loader/></b>
                )}
              </div>
              <Tabs lessonid={id} chapterId={chapterId} subjectId={subjectId} />
            </div>

            <div className="col-md-4 row">
              <div className="chapters-wise-content">
                <div className="chapters-wise-content1">
                  <h5>
                    <span className="pl-2 pr-2">
                      <img src="\SqillUPImages\chapter.svg" alt=" " />
                    </span>
                    Chapter1
                  </h5>
                  <div>
                    <div
                      className="Scroll_bar"
                      style={{
                        overflowY: "auto",
                        height: "420px",
                        overflowX: "hidden",
                      }}
                    >
                      {chapters != null
                        ? chapters.map((obj, index) => {
                            return (
                              <div
                                className="chapter-list "
                                style={{
                                  backgroundColor: index === 0 ? "#6ECACD" : "",
                                }}
                              >
                                <div
                                  className="chapter__item"
                                  onClick={() => handelPlay(obj)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <div className="chapter__column chapter__column--left">
                                    <div className="chapters-Iamge">
                                      <img
                                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTm9_iTdZ7u9oOTNzLKhBhEr1n5WA010Hjft6DmES7-xhtgyCtrsNbgYZ3Wly74f3GVnEA&usqp=CAU"
                                        alt=""
                                      />
                                    </div>
                                    <div class="chapter__info">
                                      <span className="chapter__company">
                                        Lesson {obj.number}
                                      </span>
                                      <span className="chapter__title">
                                        {obj.name}
                                      </span>
                                      <span className="chapter__time">
                                        15 Min
                                      </span>
                                    </div>
                                  </div>
                                  <div className="chapter__column chapter__column--right">
                                    <span className="tag">
                                      <img
                                        src="\SqillUPImages\pause.svg"
                                        alt=" "
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : ""}
                    </div>
                  </div>
                  {/* <div className="scrollbar-Courses" id="courses-scrollbar">
                    <div className="force-overflow1"></div>
                  </div> */}
                </div>

                {/* Glossary Data */}

                <div className="glossary-data ">
                  <h5>
                    <i>
                      <img src="\SqillUPImages\dictionary.svg" alt=" " />
                    </i>
                    Glossary
                    <span>(Lesson code 1.1)</span>
                  </h5>
                  <div className="row  glossary-content">
                    <form className="glossary-form ">
                      <div className="serch-icon">
                        <img src="\SqillUPImages\icons-Search.svg" alt=" " />
                      </div>
                      <input
                        className="form-control me-2"
                        type="search"
                        placeholder="Search"
                        aria-label="Search"
                      />
                    </form>
                    <div className="row pt-3">
                      <div className="glossary-footer">
                        <div className="row">
                          {glossarydata.length > 0 &&
                            glossarydata.map((glo, idx) => (
                              <div className="col-md-6" id={idx}>
                                {idx == 0 && (
                                  <div className="col-md-12">
                                    <h5>{glo.word.charAt(0)}</h5>
                                  </div>
                                )}
                                {idx > 0 &&
                                  glossarydata[idx - 1].word.charAt(0) !==
                                    glossarydata[idx].word.charAt(0) && (
                                    <div className="col-md-12 ">
                                      <h5>{glo.word.charAt(0)}</h5>
                                    </div>
                                  )}
                                <div className="col-md-6">
                                  <p>{glo.word}</p>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div className="text-center">
                      <Link to="#">View All</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ParticularSubject;
