import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Notification_Badges.css";
// import Courses from '../Dashboard/Courses';
import HomeSection from "../Pages/HomeSection";
import { Link } from "react-router-dom";

export default function Rewards() {
  return (
    <div>
      <div>
        <HomeSection />
        <div className="overlay"></div>
      </div>
      {/* Bages Data */}
      <div className="row">
        <div className="badges-card">
          <div className="col-md-12 d-flex col-md-offset-">
            <div className="col-md-8">
              <div className="left-badges">
                <div className="badges-line1">
                  <div class="content-overlay"></div>
                  <img
                    src="./SqillUPImages/Component 439-1.png"
                    alt=" "
                    data-text="Score 75% in math selftest"
                  />
                  <img
                    src="./SqillUPImages/Component 440-1.png"
                    alt=" "
                    data-text="Score 75% in math selftest"
                  />
                  <img
                    src="./SqillUPImages/Component 439-1.png"
                    alt=" "
                    data-text="Score 75% in math selftest"
                  />
                  <img
                    src="./SqillUPImages/Component 440-1.png"
                    alt=" "
                    data-text="Score 75% in math selftest"
                  />
                </div>
                <div className="badges-line2">
                  <img src="./SqillUPImages/walk.png" alt="" />
                  <img src="./SqillUPImages/reading.png" alt="" />
                  <img src="./SqillUPImages/television.png" alt="" />
                  <img src="./SqillUPImages/bycicle.png" alt="" />
                </div>
                <div className="badges-line3">
                  <img src="./SqillUPImages/joystick.png" alt="" />
                  <img src="./SqillUPImages/sunset.png" alt="" />
                  <img src="./SqillUPImages/Green_apple_2_.png" alt="" />
                  <img src="./SqillUPImages/hiking-boots.png" alt="" />
                </div>
                <div className="footer-buttons">
                  <Link to="/badges">
                    <button className="rewards">
                      Badges (13)
                      <div>
                        <span>view details</span>
                      </div>
                    </button>
                  </Link>
                  <Link to="/rewards">
                    <button className="badges">
                      Rewards (06)
                      <div>
                        <span>view details</span>
                      </div>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="right-rewards">
                <div className="right-rewards-image">
                  <img src="./SqillUPImages/Group 10716@2x.png" alt="" />
                </div>
                <div className="rewards-paragraph1">
                  <p>TV TIME</p>
                </div>
                <div className="rewards-paragraph2 ">
                  <p>EARNED REWARD +75 IN MATH SELFTEST</p>
                </div>
                <div className="rewards-paragraph3">
                  <hr></hr>
                  <div>
                    <p>ADD TO COLLECTION</p>
                  </div>
                  <hr></hr>
                </div>
              </div>
            </div>
          </div>
          <div className="horizontal-line">
            <hr></hr>
          </div>
          <div className="close-button">
            <Link to="/homesection">
              <button type="button">Close</button>
            </Link>
          </div>
          <div class="scrollbar" id="style-7">
            <div class="force-overflow"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
