import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Notification_Badges.css";
// import Courses from '../Dashboard/Courses';
import HomeSection from "../Pages/HomeSection";
import { badgesView } from "../Services";

import { Link } from "react-router-dom";

export default function Badges() {
  const [badgesimg, setBadgesImg] = useState("");
  const [userData, setUserData] = useState("");

  useEffect(() => {
    badgesView().then((data) => {
      setBadgesImg(data);
    });
  }, []);

  return (
    <>
      <div>
        <HomeSection />
        <div className="overlay"></div>
      </div>
      {/* Bages Data */}
      <div className="row">
        <div className="badges-card">
          <div className="col-md-12 d-flex col-md-offset-">
            <div className="col-md-8">
              <div className="left-badges">
                <div className="badges-line1">
                  <div class="content-overlay"></div>
                  <img
                    src="./SqillUPImages/Component 439-1.png"
                    alt=" "
                    data-text="Score 75% in math selftest"
                  />
                  <img
                    src="./SqillUPImages/Component 439-1.png"
                    alt=" Score 75% in math selftest"
                    data-text="Score 75% in math selftest"
                  />
                  <img
                    src="./SqillUPImages/Component 439-1.png"
                    alt=" "
                    data-text="Score 75% in math selftest"
                  />
                  <img
                    src="./SqillUPImages/Component 439-1.png"
                    alt=" "
                    data-text="Score 75% in math selftest"
                  />
                </div>
                <div className="badges-line2">
                  <img src="./SqillUPImages/medal (1).png" alt="" />
                  <img src="./SqillUPImages/uniform.png" alt="" />
                  <img src="./SqillUPImages/police-badge.png" alt="" />
                  <img src="./SqillUPImages/college.png" alt="" />
                </div>
                <div className="badges-line3">
                  <img src="./SqillUPImages/badge (2).png" alt="" />
                  <img src="./SqillUPImages/19-award.png" alt="" />
                  <img src="./SqillUPImages/badge (3).png" alt="" />
                  <img src="./SqillUPImages/badge.png" alt="" />
                </div>
                <div className="footer-buttons">
                  <Link to="/badges">
                    <button className="badges">
                      Badges (13)
                      <div>
                        <span>view details</span>
                      </div>
                    </button>
                  </Link>
                  S
                  <Link to="/rewards">
                    <button className="rewards">
                      Rewards (06)
                      <div>
                        <span>view details</span>
                      </div>
                    </button>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="right-badges">
                <div className="right-image">
                  <img src="./SqillUPImages/Group 10711@2x.png" alt="" />
                </div>
                <div className="badges-paragraph1">
                  <p>THE VIKING</p>
                </div>
                <div className="badges-paragraph2">
                  <p>EARNED BADGE +85</p>
                </div>
                <div className="badges-paragraph3">
                  <hr></hr>
                  <div>
                    <p>ADD TO COLLECTION</p>
                  </div>
                  <hr></hr>
                </div>
              </div>
            </div>
          </div>

          <div className="horizontal-line">
            <hr></hr>
          </div>
          <div className="close-button">
            <Link to="/homesection">
              <button type="button">Close</button>
            </Link>
          </div>
          <div className="scrollbar" id="style-7">
            <div class="force-overflow"></div>
          </div>
        </div>
      </div>
    </>
  );
}
