import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/pastpapers.css";

import { Link } from "react-router-dom";

function Pastpaperview() {
  return (
    <div>
      <div className="container">
        <div className="pastpaperview-wraper">
          <div className="col-md-12 d-flex">
            <div className="col-md-4 d-flex">
              <h5 className="fw-bolder">Maths</h5>
              <h5>2020</h5>
            </div>
            <div className="col-md-4 text-center ">
              <h5 className="fw-bolder">Marketing Scheme</h5>
            </div>
            <div className="col-md-4 d-md-flex justify-content-md-end">
              <img src="\SqillUPImages\Downloaded.png" alt="" />
              <Link to="/pastpaper">
                <button>Close</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="pastpaperview-data">
          <p>
            Tortor posuere ac ut consequat semper. Cursus vitae congue mauris
            rhoncus. Leo a diam sollicitudin tempor id. Iaculis at erat
            pellentesque adipiscing commodo elit at imperdiet. Quam nulla
            porttitor massa id neque aliquam. Malesuada fames ac turpis egestas
            integer eget aliquet nibh. Donec pretium vulputate sapien nec
            sagittis aliquam malesuada bibendum arcu. Interdum posuere lorem
            ipsum dolor sit. In vitae turpis massa sed elementum tempus egestas
            sed. Ultricies tristique nulla aliquet enim tortor at auctor urna
            nunc.{" "}
          </p>
          <p>
            Feugiat in fermentum posuere urna nec tincidunt praesent semper
            feugiat. Sagittis id consectetur purus ut faucibus pulvinar
            elementum integer. Quis lectus nulla at volutpat diam ut.
          </p>

          <p>
            Tortor posuere ac ut consequat semper. Cursus vitae congue mauris
            rhoncus. Leo a diam sollicitudin tempor id. Iaculis at erat
            pellentesque adipiscing commodo elit at imperdiet. Quam nulla
            porttitor massa id neque aliquam. Malesuada fames ac turpis egestas
            integer eget aliquet nibh. Donec pretium vulputate sapien nec
            sagittis aliquam malesuada bibendum arcu. Interdum posuere lorem
            ipsum dolor sit. In vitae turpis massa sed elementum tempus egestas
            sed. Ultricies tristique nulla aliquet enim tortor at auctor urna
            nunc.{" "}
          </p>
          <p>
            Feugiat in fermentum posuere urna nec tincidunt praesent semper
            feugiat. Sagittis id consectetur purus ut faucibus pulvinar
            elementum integer. Quis lectus nulla at volutpat diam ut.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Pastpaperview;
