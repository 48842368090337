import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '../Styles/Home.css';
//import { Link } from 'react-router-dom';

import { FaMinus } from "@react-icons/all-files/fa/FaMinus";

function ViewAllScore() {
    const myStyle = {
        color: "#007B85",
    };
    return (
        <>
            <div className='view-all-wraper'>
                <div className='row'>
                    <div className='col-md-12 row'>
                        <h5>Score</h5>
                        <div className='col-md-8'>
                            <div className='view-all-left-dta'>
                                <table className='table'>
                                    <thead>
                                        <th>Lesson Name</th>
                                        <th>Score</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><span>1.1 The particles models</span></td>
                                            <td><span>19</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>1.2 Freezing and melting point</span></td>
                                            <td><span>09</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>1.3 Organic chemistry</span></td>
                                            <td><span>10</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>1.4 solid base and salt</span></td>
                                            <td><span>12</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>1.5 Particles and their behaviour</span></td>
                                            <td><span><FaMinus /></span></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="row view_paragraph">
                                    <div className="col-md-6">
                                        <span id="view_p1">Total score: 50</span>
                                    </div>
                                    <div className="col-md-6">
                                        <span id="view_p2">Average percentage: 12.5%</span>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className='col-md-4'>
                            <div className='view-all-right-dta '>
                                <select className="dropdown-toggle" >
                                    <option value="maths">Maths</option>
                                    <option value="chemistry">Chemistry</option>
                                    <option value="physics">Physics</option>
                                    <option value="biology">Biology</option>
                                </select>
                                <select className="dropdown-toggle " >
                                    <option value="maths">SelfTest</option>
                                    <option value="chemistry">WorkSheet</option>
                                    <option value="physics">ChapterPaper</option>
                                    <option value="biology">Challenges</option>
                                </select>
                                <select className="dropdown-toggle " >
                                    <option value="maths" style={myStyle}>Chapter 1</option>
                                    <option value="chemistry">1.1 The particle model</option>
                                    <option value="physics">1.2 freezing and melting</option>
                                    <option value="biology">1.3 State of matter</option>
                                    <option value="maths" style={myStyle}>.Chapter 2</option>
                                    <option value="chemistry">2.1 State of matter</option>
                                    <option value="physics">2.2 The particle model</option>
                                    <option value="biology">1.3 State of matter</option>
                                    <option value="biology" style={myStyle}>.Chapter 3</option>
                                    <option value="biology" style={myStyle}>.Chapter 4</option>
                                </select>
                                <div className="view-all-right-button">
                                    <button className="btn" disabled data-bs-toggle="button">Apply</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ViewAllScore