import axios from "axios";
// import {toast} from 'react-toastify';
import {
  baseURL,
  studentLogin,
  studentProfile,
  studentViewAll,
  selfTest,
  worksheet,
  chapter,
  examType,
  allLessons,
  examboard,
  pastpaper,
  revision,
  curriculum,
  viewall,
  notification,
  viewnotification,
  studentnotification,
  viewscore,
  notice,
  greetings,
  score,
  ongoing_student,
  search,
  glossary,
  badges,
  rewards,
  studentschooldetails,
  upcomingEvents,
} from "../Constants";

const headers = {
  Authorization: "Bearer " + sessionStorage.getItem("sqlupToken"), //the token is a variable which holds the token
};

// SigninPage Component Integration
export const profileLogin = async (requestData) => {
  return await axios
    .post(baseURL + studentLogin, requestData, {
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
// MyProfile Component Integration
export const myProfile = async (requestData) => {
  return await axios
    .post(baseURL + studentProfile, requestData, {
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
// View ALL Component Integration Subject API
export const homeViewAll = async (requestData) => {
  return await axios
    .post(baseURL + studentViewAll, requestData, {
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
//Exam Board API Integration
export const studentExamBoard = async (requestData) => {
  return await axios
    .post(baseURL + examboard, requestData, {
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
//PapstPaper API Integration
export const studentPastPaper = async (requestData) => {
  return await axios
    .post(baseURL + pastpaper, requestData, {
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
// View Lesson By Id Component Integration
export const GenericGet = async (requestData, url) => {
  return await axios
    .post(baseURL + url, requestData, {
      headers,
    })

    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
export const GenericPost = async (requestData, url) => {
  return await axios
    .post(baseURL + url, requestData, {
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
//View All ExamType Integration
export const studentExamType = async (requestData) => {
  return await axios
    .post(baseURL + examType, requestData, {
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
//View All chapter Integration
export const studentChapterdata = async (requestData) => {
  return await axios
    .post(baseURL + chapter, requestData, {
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
// Courses ViewAll Lessons Integration
export const studentViewAllLessons = async (requestData) => {
  var bodyFormData = new FormData();
  bodyFormData.append("subject_id", requestData.subject_id);
  bodyFormData.append("chapter_id", requestData.chapter_id);

  return await axios
    .post(baseURL + allLessons, requestData, {
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      // toast.error("Something went wrong.")
      throw err;
    });
};
//ongoing Viweall Lessons Integration
export const ongoing_lessons = async (status) => {
  var bodyFormData = new FormData();
  bodyFormData.append("user_id", sessionStorage.loginUser);
  bodyFormData.append("status", status);
  // bodyFormData.append("chapter_id", requestData.chapter_id);

  return await axios
    .post(baseURL + ongoing_student, bodyFormData, {
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
//SelfTest Component Integration
export const studentSelftest = async (requestData) => {
  return await axios
    .post(baseURL + selfTest, requestData, {
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
//worksheet Component Integration
export const studentWorksheet = async (requestData) => {
  return await axios
    .post(baseURL + worksheet, requestData, {
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
//worksheet Component Integration
export const getAllQuestion = async (requestData, url) => {
  // const body = {
  //   lesson_id: requestData.lessonId.lessonid,
  // };
  var bodyFormData = new FormData();
  bodyFormData.append("lesson_id", requestData.lessonId.lessonid);

  return await axios
    .post(baseURL + url, bodyFormData, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("sqlupToken"),
        "Content-Type": "multipart/form-data",
      },
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
//Revision Services Integration
export const studentRevision = async (requestData) => {
  var bodyFormData = new FormData();
  bodyFormData.append("subject_id", requestData);
  return await axios
    .post(baseURL + revision, bodyFormData, {
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
//Curriculum Services Integration
export const studentCurriculum = async (requestData) => {
  var bodyFormData = new FormData();
  bodyFormData.append("subject_id", requestData);
  // bodyFormData.append("unit_or_chapter", "chapter");
  return await axios
    .post(baseURL + curriculum, bodyFormData, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("sqlupToken"),
        "Content-Type": "multipart/form-data",
      },
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
//PastPaer Component Integration
export const getAllPastPaper = async (requestData, hideYear) => {
  var bodyFormData = new FormData();
  bodyFormData.append("subject_id", requestData.subject_id);
  bodyFormData.append("exam_board_id", requestData.exam_board_id);
  if (hideYear) {
    bodyFormData.append("chapter_id", requestData.chapter_id);
  } else {
    bodyFormData.append("year", requestData.year);
  }
  return await axios
    .post(baseURL + pastpaper, bodyFormData, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("sqlupToken"),
        "Content-Type": "multipart/form-data",
      },
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
//ViewAll Student Integration Methods
export const viweallstudentscore = async (requestData) => {
  var bodyFormData = new FormData();

  bodyFormData.append("subject_id", requestData.subject_id);
  bodyFormData.append(" lesson_type_id", requestData.lesson_type_id);
  bodyFormData.append("chapter_id", requestData.chapter_id);
  bodyFormData.append("lesson_id", requestData.lesson_id);

  return await axios
    .post(baseURL + viewall, bodyFormData, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("sqlupToken"),
        "Content-Type": "multipart/form-data",
      },
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
//Notice Card Integration API
export const notice_student = async () => {
  var bodyFormData = new FormData();

  bodyFormData.append("subject_id", "subject_id");
  bodyFormData.append("lesson_id", "lesson_id");

  return await axios
    .post(baseURL + notice, bodyFormData, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("sqlupToken"),
        "Content-Type": "multipart/form-data",
      },
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
//Score Card API Integration
export const viweall_total_avg = async (requestData) => {
  var bodyFormData = new FormData();

  bodyFormData.append("subject_id", requestData.subject_id);
  bodyFormData.append(" lesson_type_id", requestData.lesson_type_id);
  bodyFormData.append("chapter_id", requestData.chapter_id);
  bodyFormData.append("lesson_id", requestData.lesson_id);

  return await axios
    .post(baseURL + viewscore, bodyFormData, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("sqlupToken"),
        "Content-Type": "multipart/form-data",
      },
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
//Notification Admin and Payment Services Integration
export const studentNotification = async (requestData) => {
  var bodyFormData = new FormData();
  bodyFormData.append("user_id", requestData.user_id);
  bodyFormData.append(
    "payment_push_notification",
    requestData.payment_push_notification
  );
  bodyFormData.append(
    " payment_email_notification",
    requestData.payment_email_notification
  );
  bodyFormData.append(
    "payment_sms_notification",
    requestData.payment_sms_notification
  );
  bodyFormData.append(
    "admin_push_notification",
    requestData.admin_push_notification
  );
  bodyFormData.append(
    "admin_email_notification",
    requestData.admin_email_notification
  );
  bodyFormData.append(
    "admin_sms_notification",
    requestData.admin_sms_notification
  );

  return await axios
    .post(baseURL + notification, bodyFormData, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("sqlupToken"),
        "Content-Type": "multipart/form-data",
      },
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
//Notification Student API Integration
export const studentviewNotification = async () => {
  var bodyFormData = new FormData();

  bodyFormData.append("user_id", sessionStorage.loginUser);

  return await axios
    .post(baseURL + viewnotification, bodyFormData, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("sqlupToken"),
        "Content-Type": "multipart/form-data",
      },
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
//Notification Student API Integration
export const notification_student = async () => {
  var bodyFormData = new FormData();
  bodyFormData.append("target", "student");

  return await axios
    .post(baseURL + studentnotification, bodyFormData, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("sqlupToken"),
        "Content-Type": "multipart/form-data",
      },
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
//Student Greetings Integration
export const greetings_student = async () => {
  var bodyFormData = new FormData();
  bodyFormData.append("target", "student");

  return await axios
    .post(baseURL + greetings, bodyFormData, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("sqlupToken"),
        "Content-Type": "multipart/form-data",
      },
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
//Student Card Score Data Imntegration
export const student_card_score = async (requestData) => {
  var bodyFormData = new FormData();

  bodyFormData.append("user_id", sessionStorage.loginUser);

  return await axios
    .post(baseURL + score, bodyFormData, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("sqlupToken"),
        "Content-Type": "multipart/form-data",
      },
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
// Search Bar Integration Services
export const serach_bar = async (requestData) => {
  var bodyFormData = new FormData();

  bodyFormData.append("search", requestData);

  return await axios
    .post(baseURL + search, bodyFormData, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("sqlupToken"),
        "Content-Type": "multipart/form-data",
      },
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
//Glossary Data
export const studentGlossary = async () => {
  var bodyFormData = new FormData();
  bodyFormData.append("lesson_id", "38");

  return await axios
    .post(baseURL + glossary, bodyFormData, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("sqlupToken"),
        "Content-Type": "multipart/form-data",
      },
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
//Badges Data

export const badgesView = async () => {
  var bodyFormData = new FormData();

  bodyFormData.append("user_id", sessionStorage.loginUser);
  return await axios
    .post(baseURL + badges, bodyFormData, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("sqlupToken"),
        "Content-Type": "multipart/form-data",
      },
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
//Rewards Data
export const rewardsView = async (requestData) => {
  return await axios
    .post(baseURL + rewards, requestData, {
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
//Calender Data
export const viewSchoolDetails = async (requestData) => {
  return await axios
    .post(baseURL + studentschooldetails, requestData, {
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
export const  viewStudentUpcomingEvents= async (requestData) => {
  return await axios
    .post(baseURL + upcomingEvents, requestData, {
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};