import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '../Styles/Challenge.css';
import { Link } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';
function UKMT() {

  let navigate = useNavigate();
  function changeHandler() {
    navigate('/selftest')
  }

  return (
    <div>
       <div className="Challenge-wrapper">
        <h5 className='mb-3'>Challenges</h5>
        <nav className="navbar navbar-default Challenge-wrapper-Dropdown">
          <div className="container">
            <ul className="nav navbar-nav">
              <li className="dropdown">
                <button data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                  Select Menu
                  <span>
                    <img src="\SqillUPImages\dropdown-arrow.svg" alt="" />
                  </span>
                </button>
                <div className="Dropdown-data">
                  <ul className="dropdown-menu">
                    <li>
                      <Link to="/challenge" className="dropdown-item" >
                        <img src="\SqillUPImages\UKBC.png" alt="" />
                        <p>UKBC</p>
                      </Link>
                    </li>
                    <li>
                      <Link to="/bpho" className="dropdown-item" >
                        <img src="\SqillUPImages\BPHO.png" alt="" />
                        <p>BPHO</p>
                      </Link>
                    </li>
                    <li>
                      <Link to="/rsoc" className="dropdown-item" >
                        <img src="\SqillUPImages\RSOC.png" alt="" />
                        <p>RSOC</p>
                      </Link>
                    </li>
                    <li>
                      <Link to="/ukmt" className="dropdown-item" >
                        <img src="\SqillUPImages\UKMT.png" alt="" />
                        <p>UKMT</p>
                      </Link>
                    </li>

                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </nav>
        <div className="row"onClick={changeHandler}>
          <div className="col-md-12 mt-5">
            <div className="challenge-content-UKMT ">
              <div className="card-group d-flex">
                <div className=" col-sm-2 col-lg-2 col-md-2">
                <div className="card">
                  <div className="card-header d-flex">
                    <div className="col-md-10">
                      <p>UKMT Challenges 2020</p>
                    </div>
                    <div className="col-md-2">
                      <img src="\SqillUPImages\UKMTLogo.png" alt="" />
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="card-title">
                      <p>Questions: <span>30</span></p>
                      <p>Duration: <span>30 Mins</span></p>
                    </div>
                  </div>
                  <div className="card-footer">
                    <p><Link to="#">Start Test</Link></p>
                  </div>
                </div>
                </div>
                <div className=" col-sm-2 col-lg-2 col-md-2">
                <div className="card">
                  <div className="card-header d-flex">
                    <div className="col-md-10">
                      <p>UKMT Challenges 2020</p>
                    </div>
                    <div className="col-md-2">
                    <img src="\SqillUPImages\UKMTLogo.png" alt="" />
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="card-title">
                      <p>Questions: <span>30</span></p>
                      <p>Duration: <span>30 Mins</span></p>
                    </div>
                  </div>
                  <div className="card-footer">
                    <p><Link to="#">Start Test</Link></p>
                  </div>
                </div>
                </div>
                <div className=" col-sm-2 col-lg-2 col-md-2">
                <div className="card">
                  <div className="card-header d-flex">
                    <div className="col-md-10">
                      <p>UKMT Challenges 2020</p>
                    </div>
                    <div className="col-md-2">
                    <img src="\SqillUPImages\UKMTLogo.png" alt="" />
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="card-title">
                      <p>Questions: <span>30</span></p>
                      <p>Duration: <span>30 Mins</span></p>
                    </div>
                  </div>
                  <div className="card-footer">
                    <p><Link to="#">Start Test</Link></p>
                  </div>
                </div>
                </div>
                <div className=" col-sm-2 col-lg-2 col-md-2">
                <div className="card">
                  <div className="card-header d-flex">
                    <div className="col-md-10">
                      <p>UKMT Challenges 2020</p>
                    </div>
                    <div className="col-md-2">
                    <img src="\SqillUPImages\UKMTLogo.png" alt="" />
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="card-title">
                      <p>Questions: <span>30</span></p>
                      <p>Duration: <span>30 Mins</span></p>
                    </div>
                  </div>
                  <div className="card-footer">
                    <p><Link to="#">Start Test</Link></p>
                  </div>
                </div>
                </div>
                <div className=" col-sm-2 col-lg-2 col-md-2">
                <div className="card">
                  <div className="card-header d-flex">
                    <div className="col-md-10">
                      <p>UKMT Challenges 2020</p>
                    </div>
                    <div className="col-md-2">
                    <img src="\SqillUPImages\UKMTLogo.png" alt="" />
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="card-title">
                      <p>Questions: <span>30</span></p>
                      <p>Duration: <span>30 Mins</span></p>
                    </div>
                  </div>
                  <div className="card-footer">
                    <p><Link to="#">Start Test</Link></p>
                  </div>
                </div>
                </div>
                <div className=" col-sm-2 col-lg-2 col-md-2">
                <div className="card">
                  <div className="card-header d-flex">
                    <div className="col-md-10">
                      <p>UKMT Challenges 2020</p>
                    </div>
                    <div className="col-md-2">
                    <img src="\SqillUPImages\UKMTLogo.png" alt="" />
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="card-title">
                      <p>Questions: <span>30</span></p>
                      <p>Duration: <span>30 Mins</span></p>
                    </div>
                  </div>
                  <div className="card-footer">
                    <p><Link to="#">Start Test</Link></p>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 mt-4">
            <div className="challenge-content-UKMT ">
              <div className="card-group d-flex">
                <div className=" col-sm-2 col-lg-2 col-md-2">
                <div className="card">
                  <div className="card-header d-flex">
                    <div className="col-md-10">
                      <p>UKMT Challenges 2020</p>
                    </div>
                    <div className="col-md-2">
                    <img src="\SqillUPImages\UKMTLogo.png" alt="" />
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="card-title">
                      <p>Questions: <span>30</span></p>
                      <p>Duration: <span>30 Mins</span></p>
                    </div>
                  </div>
                  <div className="card-footer">
                    <p><Link to="#">Start Test</Link></p>
                  </div>
                </div>
                </div>
                <div className=" col-sm-2 col-lg-2 col-md-2">
                <div className="card">
                  <div className="card-header d-flex">
                    <div className="col-md-10">
                      <p>UKMT Challenges 2020</p>
                    </div>
                    <div className="col-md-2">
                    <img src="\SqillUPImages\UKMTLogo.png" alt="" />
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="card-title">
                      <p>Questions: <span>30</span></p>
                      <p>Duration: <span>30 Mins</span></p>
                    </div>
                  </div>
                  <div className="card-footer">
                    <p><Link to="#">Start Test</Link></p>
                  </div>
                </div>
                </div>
                <div className=" col-sm-2 col-lg-2 col-md-2">
                <div className="card">
                  <div className="card-header d-flex">
                    <div className="col-md-10">
                      <p>UKMT Challenges 2020</p>
                    </div>
                    <div className="col-md-2">
                    <img src="\SqillUPImages\UKMTLogo.png" alt="" />
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="card-title">
                      <p>Questions: <span>30</span></p>
                      <p>Duration: <span>30 Mins</span></p>
                    </div>
                  </div>
                  <div className="card-footer">
                    <p><Link to="#">Start Test</Link></p>
                  </div>
                </div>
                </div>
                <div className=" col-sm-2 col-lg-2 col-md-2">
                <div className="card">
                  <div className="card-header d-flex">
                    <div className="col-md-10">
                      <p>UKMT Challenges 2020</p>
                    </div>
                    <div className="col-md-2">
                    <img src="\SqillUPImages\UKMTLogo.png" alt="" />
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="card-title">
                      <p>Questions: <span>30</span></p>
                      <p>Duration: <span>30 Mins</span></p>
                    </div>
                  </div>
                  <div className="card-footer">
                    <p><Link to="#">Start Test</Link></p>
                  </div>
                </div>
                </div>
                <div className=" col-sm-2 col-lg-2 col-md-2">
                <div className="card">
                  <div className="card-header d-flex">
                    <div className="col-md-10">
                      <p>UKMT Challenges 2020</p>
                    </div>
                    <div className="col-md-2">
                    <img src="\SqillUPImages\UKMTLogo.png" alt="" />
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="card-title">
                      <p>Questions: <span>30</span></p>
                      <p>Duration: <span>30 Mins</span></p>
                    </div>
                  </div>
                  <div className="card-footer">
                    <p><Link to="#">Start Test</Link></p>
                  </div>
                </div>
                </div>
                <div className=" col-sm-2 col-lg-2 col-md-2">
                <div className="card">
                  <div className="card-header d-flex">
                    <div className="col-md-10">
                      <p>UKMT Challenges 2020</p>
                    </div>
                    <div className="col-md-2">
                    <img src="\SqillUPImages\UKMTLogo.png" alt="" />
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="card-title">
                      <p>Questions: <span>30</span></p>
                      <p>Duration: <span>30 Mins</span></p>
                    </div>
                  </div>
                  <div className="card-footer">
                    <p><Link to="#">Start Test</Link></p>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UKMT