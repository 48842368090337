import React from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {Oval} from "react-loader-spinner";

function Loader() {
  return (
    <div>
      <Oval
        height="50"
        width="30"
        color="#6ECACD"
        ariaLabel="oval-loading"
        secondaryColor="#6ECACD"
        // colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
      />
    </div>
  );
}

export default Loader;
