import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Signin.css";
import { AiFillEye } from "@react-icons/all-files/ai/AiFillEye";
import { AiFillEyeInvisible } from "@react-icons/all-files/ai/AiFillEyeInvisible";
import { AiOutlineCheck } from "@react-icons/all-files/ai/AiOutlineCheck";
import { AiOutlineInfoCircle } from "@react-icons/all-files/ai/AiOutlineInfoCircle";
import { Link, useNavigate } from "react-router-dom";
import UseForm from "../LoginFormValidations/UseForm";
import { profileLogin } from "../Services";

const SigninPage = ({ submitForm }) => {
  const [userName, setUserName] = useState("");
  const [userSecret, setUserSecret] = useState("");
  const [valid, setValid] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [passwordType, setPasswordType] = useState("password");
  let [errorMessage, seterrorMessage] = useState("");

  let navigate = useNavigate();

  const validate = (values) => {
    const errors = {};

    if (!values.user_name) {
      errors.userName = "invalid Login details!";
    } else if (!values.password) {
      errors.password =
        "Your password contains numbers , symbols and characters";
    } else if (values.password.length < 4) {
      errors.password = "Password must be more than 4 characters";
    } else if (values.password.length > 10) {
      errors.password = "Password cannot exceed more than 10 characters";
    }
    return errors;
  };

  const greetUser = () => {
    const studentLoginData = {
      user_name: userName,
      password: userSecret,
    };
    setFormErrors(validate(studentLoginData));
    profileLogin(studentLoginData)
      .then((data) => {
        setValid(false);

        sessionStorage.setItem("sqlupToken", data.token);
        sessionStorage.setItem("loginUser", data.user?.id);
        navigate("/homesection");
      })
      .catch((error) => {
        seterrorMessage(error?.response?.data?.message);
        setValid(true);
      });
    // profileLogin(studentLoginData).then((responseData)=>{

    //})
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const handelCheck = () => {};

  return (
    <>
      <div className="right-content">
        <div className="signin">
          <h1>Welcome back</h1>
        </div>
        <div className="p2">
          <p>Sign in to your account</p>
        </div>
        <div className="p3">
          <p>Enter your email address and password below</p>
        </div>
        <div className="row">
          <div className="p4">
            <label className="Label">Sign in as</label>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio1"
                value="parent"
              />
              <label className="form-check-label" htmlFor="inlineRadio1">
                Parent
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio2"
                value="student"
              />
              <label className="form-check-label" htmlFor="inlineRadio2">
                Student
              </label>
            </div>
          </div>
        </div>
        <form className="student-form">
          {formErrors.userName != undefined &&
          formErrors.userName.length > 0 ? (
            <span className="error-msg">
              <span className="pr-2">
                <AiOutlineInfoCircle />
              </span>
              {/* Invalid Login details */}
              {formErrors.userName}
            </span>
          ) : (
            <pre></pre>
          )}

          {/* login error msg */}

          <div className="form-group">
            <label>Enter username</label>
            <input
              type="text"
              id="uname"
              name="uname"
              placeholder="Username"
              className="form-control invalid-error"
              onKeyUp={(event) => setUserName(event.target.value)}
            ></input>
            <div className="eye-visible">
              <AiOutlineCheck />
            </div>
            <p></p>
          </div>

          <div className="form-group">
            <label>Password</label>
            <input
              type={passwordType}
              id="pass"
              name="password"
              placeholder="password"
              className="form-control invalid-error"
              onKeyUp={(event) => setUserSecret(event.target.value)}
            ></input>
            <div className="eye-visible" onClick={togglePassword}>
              {passwordType === "password" ? (
                <AiFillEye />
              ) : (
                <AiFillEyeInvisible />
              )}
            </div>
            {/* login error msg */}

            {formErrors.password != undefined &&
            formErrors.password.length > 0 ? (
              <span className="error-msg">
                <span className="pr-2">
                  <AiOutlineInfoCircle />
                </span>
                {formErrors.password}
              </span>
            ) : (
              <pre></pre>
            )}
            <span className="error-msg">{errorMessage}</span>
          </div>

          <div className="form-group">
            <input
              type="checkbox"
              formcontrolname="checkbox"
              id="exampleCheck1"
              className="form-check-input"
              onClick={handelCheck}
            />
            <label htmlFor="exampleCheck1" className="form-check-label">
              Remember Me
            </label>
            <Link to="/forgot" className="forgot">
              Forgot Password?
            </Link>
          </div>
        </form>
        <div className="Signin-Button">
          <button type="submit" onClick={greetUser}>
            SIGN IN
          </button>
        </div>
      </div>
    </>
  );
};
export default SigninPage;
