import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/pastpapers.css";

import {
  homeViewAll,
  studentExamBoard,
  getAllPastPaper,
  studentChapterdata,
} from "../Services";

import { year } from "../Constants";
import { base } from "../Constants";

let initialValues = {
  subject_id: "",
  exam_board_id: "",
  year: "",
  chapter_id: "",
};

function PastPaper() {
  const [pastPaper, setPastPaper] = useState([]);
  const [hideYear, setHideYear] = useState(false);
  const [requestData, setRequestData] = useState(initialValues);
  const [allSubject, setAllSubject] = useState([]);
  const [examboard, setExamboard] = useState([]);
  const [chapterdata, setChapterData] = useState([]);

  useEffect(() => {
    homeViewAll().then((data) => {
      setAllSubject(data);
    });
    studentExamBoard().then((data) => {
      setExamboard(data);
    });

    studentChapterdata().then((data) => {
      setChapterData(data);
    });
    getAllPastPaper(requestData).then((data) => {
      setPastPaper(data);
    });
  }, []);

  const handleCheckBox = (event) => {
    setHideYear(event.target.checked);
  };

  const handleSelectChange = (event) => {
    setRequestData({ ...requestData, [event.target.name]: event.target.value });

    getAllPastPaper(
      { ...requestData, [event.target.name]: event.target.value },
      hideYear
    ).then((data) => {
      setPastPaper(data);
    });
  };

  return (
    <div className="pastpaper-wrapper">
      <h5 className="mb-3">Past Papers</h5>
      <div className="row">
        <div className="col-md-8">
          <div className="row mb-2">
            <div className="pastpaper-right1 col-md-2 ">
              <select
                className="dropdown-toggle"
                name="subject_id"
                onChange={handleSelectChange}
              >
                <option value="">Select Subject</option>
                {allSubject.length &&
                  allSubject.map((data, index) => (
                    <option value={data.id}>{data.name}</option>
                  ))}
              </select>
            </div>
            <div className="pastpaper-right2 col-md-2 p-0">
              <select
                className="dropdown-toggle "
                name="exam_board_id"
                onChange={handleSelectChange}
              >
                <option value="">Select Exam Board</option>
                {examboard.length &&
                  examboard.map((data, index) => (
                    <option value={data.id}>{data.name}</option>
                  ))}
              </select>
            </div>
            <div className="pastpaper-right2 col-md-2">
              {hideYear ? (
                <select
                  className="dropdown-toggle"
                  name="chapter_id"
                  onChange={handleSelectChange}
                >
                  <option value="">Select Chapter</option>
                  {chapterdata.length &&
                    chapterdata.map((data, index) => (
                      <option value={data.id}>{data.name}</option>
                    ))}
                </select>
              ) : (
                <select
                  className="dropdown-toggle"
                  name="year"
                  onChange={handleSelectChange}
                >
                  <option value="">Select Year</option>
                  {year.length &&
                    year.map((data, index) => (
                      <option value={data}>{data}</option>
                    ))}
                </select>
              )}
            </div>
            <div className="col-md-2 offset-md-10 year-tag">
              <div class="switch_box2 box_2">
                {hideYear ? <label>Chapter</label> : <label>year</label>}
                <input
                  type="checkbox"
                  class="switch_2"
                  onClick={handleCheckBox}
                />
              </div>
            </div>
          </div>
          <div className="past-table">
            <table className="table">
              <thead>
                <th></th>
                <th>Subject</th>
                <th>Board</th>
                <th>{hideYear ? "Chapter" : "Year"}</th>
                {!hideYear && <th>Paper Type</th>}
                {!hideYear && <th></th>}
                <th>{hideYear ? "Download Paper" : null}</th>
                <th>Action</th>
              </thead>
              <tbody>
                {pastPaper.length > 0 &&
                  pastPaper.map((paper) => (
                    <tr >
                      <td>&nbsp;&nbsp;&nbsp;
                        <input type="checkbox"/>
                      </td>
                      <td className="course-title">
                        <span>{paper?.subject?.name}</span>
                      </td>
                      <td>{paper?.exam_board?.name}</td>

                      <td>{hideYear ? paper?.chapter?.name : paper?.year}</td>

                      {!hideYear && <td>{paper?.paper_type}</td>}
                      {!hideYear && <td>Paper1</td>}

                      <td>
                        <a href={`${base}${paper.paper_file}`} target="_blank">
                          <button className="btn btn-primary">Download</button>
                        </a>
                      </td>

                      <td className="start-test">Start Test</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-md-4">
          <div className="market-top">
            <div className="past-table">
              <table className="table">
                <thead>
                  <th style={{ textAlign: "center" }}>Marking Scheme</th>
                </thead>
                <tbody>
                  {pastPaper.length > 0 &&
                    pastPaper.map((paper) => (
                      <tr>
                        <td style={{ textAlign: "center" }}>
                          <a
                            href={`${base}${paper.paper_marking_scheme}`}
                            target="_blank"
                          >
                            <button className="btn-View btn-info">View</button>
                          </a>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PastPaper;
