import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '../Styles/Courses.css';
import { AiFillPlayCircle } from "@react-icons/all-files/ai/AiFillPlayCircle";
import {RiRefreshFill } from "@react-icons/all-files/ri/RiRefreshFill";
import {AiFillHeart } from "@react-icons/all-files/ai/AiFillHeart";
import {AiFillCheckCircle} from "@react-icons/all-files/ai/AiFillCheckCircle";
import {AiOutlineHeart} from "@react-icons/all-files/ai/AiOutlineHeart";

import {Link} from 'react-router-dom';
export default function Completed() {
  return (
    <div>
       <div className="courses-wrapper">
        <h5 className='mb-3'>Chemistry<span className="v1"></span> C 1: State of matter</h5>
        <div className='row mb-3'>
          <div className='col-md-9'>
            <div className="tabs mb-2">
              <input type="radio" id="tab1" name="tab-control" checked />
              <input type="radio" id="tab2" name="tab-control" />
              <input type="radio" id="tab3" name="tab-control" />
              <input type="radio" id="tab4" name="tab-control" />
              <ul>
                <Link to="/courses">
                    <li><label for="tab1" role="button">
                    <span className='pl-2 pr-2 icon1'><AiFillPlayCircle /></span><span className='all-lessons'>All Lessons</span></label></li>
                  </Link>
                  {/* <Link to="/ongoing">
                    <li><label for="tab2" role="button">
                    <span className='pl-2 pr-2 icon2'><RiRefreshFill/></span><span>Ongoing</span></label></li>
                  </Link> */}
                  <Link to='/wishlist'>
                  <li><label for="tab3" role="button">
                  <span className='pl-2 pr-2 icon3'><AiFillHeart/></span><span>Wishlist</span></label></li>
                  </Link>
                  <Link to="/completed">
                  <li><label for="tab4" role="button">
                  <span className='pl-2 pr-2 icon4'><AiFillCheckCircle/></span><span>Completed</span></label></li>
                  </Link>

              </ul>
              <div className="slider">
                <div className="indicator"></div>
              </div>
              <div className="slider1">
                <div className="indicator"></div>
              </div>
              {/* <div className="courses-left">
                        <p><span><AiFillPlayCircle /></span>All Lessons</p>
                        <hr></hr>
                      </div> */}
            </div>
            {/* <div class="content">
              <section>

              </section>
            </div> */}
          </div>
          <div className="col-md-3 d-flex ">
            <div className="courses-right1 ">
              <select className="dropdown-toggle " >
                <option value="chemistry">Chemistry</option>
                <option value="physics">Physics</option>
                <option value="biology">Biology</option>
                <option value="maths">Maths</option>
              </select>
            </div>
            <div className="courses-right2">
              <select className="dropdown-toggle " >
                <option value="chemistry">All</option>
                <option value="chemistry">Chapter 1</option>
                <option value="physics">Chapter 2</option>
                <option value="biology">Chapter 3</option>
                <option value="maths">Chapter 4</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 ">
            <div className="course-content">
              <div class="card ">
                <div className="card-body">
                  <p class="card-title">1.1 State of Matter <span><AiOutlineHeart/></span></p>
                  <img src="https://cdn.pixabay.com/photo/2014/04/02/16/20/chemistry-306984__340.png" className="card-image card-title card-icon" alt="" />
                  <time>12:30</time>
                </div>
                <div className="card-footer">
                  <p id="p1"><b>1.1 The Particle Model</b></p>
                  <p id="p2">Stephen Shaw</p>
                  <p id="p3">250 views</p><p id="p4"><li>1 week ago</li></p>
                </div>
                <div className="slider3">
                  <div className="indicator"></div>
                </div>
              </div>
              <div class="card ">
              <div className="card-body">
                  <p class="card-title">1.1 State of Matter <span><AiOutlineHeart/></span></p>
                  <img src="https://cdn.pixabay.com/photo/2014/04/02/16/20/chemistry-306984__340.png" className="card-image card-title card-icon" alt="" />
                  <time>12:30</time>
                </div>
                <div className="card-footer">
                  <p id="p1"><b>1.1 The Particle Model</b></p>
                  <p id="p2">Stephen Shaw</p>
                  <p id="p3">250 views</p><p id="p4"><li>1 week ago</li></p>
                </div>
                <div className="slider3">
                  <div className="indicator"></div>
                </div>
              </div>
              <div class="card ">
              <div className="card-body">
                  <p class="card-title">1.1 State of Matter <span><AiOutlineHeart/></span></p>
                  <img src="https://cdn.pixabay.com/photo/2014/04/02/16/20/chemistry-306984__340.png" className="card-image card-title card-icon" alt="" />
                  <time>12:30</time>
                </div>
                <div className="card-footer">
                  <p id="p1"><b>1.1 The Particle Model</b></p>
                  <p id="p2">Stephen Shaw</p>
                  <p id="p3">250 views</p><p id="p4"><li>1 week ago</li></p>
                </div>
                <div className="slider3">
                  <div className="indicator"></div>
                </div>
              </div>
              <div class="card ">
              <div className="card-body">
                  <p class="card-title">1.1 State of Matter <span><AiOutlineHeart/></span></p>
                  <img src="https://cdn.pixabay.com/photo/2014/04/02/16/20/chemistry-306984__340.png" className="card-image card-title card-icon" alt="" />
                  <time>12:30</time>
                </div>
                <div className="card-footer">
                  <p id="p1"><b>1.1 The Particle Model</b></p>
                  <p id="p2">Stephen Shaw</p>
                  <p id="p3">250 views</p><p id="p4"><li>1 week ago</li></p>
                </div>
                <div className="slider3">
                  <div className="indicator"></div>
                </div>
              </div>

            </div>
        </div>
      </div>
    </div>
    </div>
  )
}
