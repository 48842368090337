import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '../Styles/CoursesTabs.css';

import { Link } from 'react-router-dom';

function SelftestSubmitScreen() {
    return (
        <div className="selftest_container-wraper">
            <div className="col-md-12 d-md-flex justify-content-md-end">
                <div className="selftest_CloseButton ">
                    <Link to="/particularsubject"><button>Close</button></Link>
                </div>
            </div>
                <div className="selftest_Submit_message text-center">
                    <div>
                        <span><img src="\SqillUPImages\Tickmark.svg" alt=""/></span>
                        <h6>Test Completed</h6>
                        <p>You have successfully completed your test!</p>
                    </div>
                </div>
            
        </div>
    )
}

export default SelftestSubmitScreen