import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Home.css";

import {
  homeViewAll,
  studentExamType,
  studentChapterdata,
  viweallstudentscore,
  viweall_total_avg
} from "../Services";

let initialValues = {
  subject_id: "",
  lesson_type_id: "",
  chapter_id: "",
  lesson_id: "",
};

function ViewAll() {
  const [requestData, setRequestData] = useState(initialValues);
  const [allSubject, setAllSubject] = useState([]);
  const [examtype, setExamtype] = useState([]);
  const [chapterdata, setChapterData] = useState([]);
  const [studentviewall, setStudentViewAll] = useState([]);
  const [hidetable, setHidetable] = useState(false);
  const [validate, setValidate] = useState();
  const [totalavg,setTotalAvg] = useState([]);

  useEffect(() => {
    homeViewAll().then((data) => {
      setAllSubject(data);
    });
    studentExamType().then((data) => {
      setExamtype(data);
    });
    studentChapterdata().then((data) => {
      setChapterData(data);
    });
   
  }, []);

  const handleSubjectChange = (event) => {
    setRequestData({ ...requestData, [event.target.name]: event.target.value });
    setValidate("");
  };

  const handelApply = () => {
    if ( requestData.chapter_id && requestData.subject_id && requestData.lesson_type_id) {
      viweallstudentscore(requestData).then((data) => {
        setStudentViewAll(data);
        setHidetable(true);
      });
      viweall_total_avg(requestData).then((data)=>{
        setTotalAvg(data);
      });
      setValidate("");
    } else {
      setValidate("Please Select Below data");
    }
   
  };
  return (
    <>
      <div className="view-all-wraper">
        <div className="row">
          <div className="col-md-12 row">
            <h5>Score</h5>
            <div className="col-md-8">
              {hidetable ? (
                <div className="row">
                  <div className="view-all-left-dta">
                    <table className="table">
                      <thead>
                        <th>Lesson Name</th>
                        <th>Score</th>
                      </thead>
                      {studentviewall.length > 0 &&
                        studentviewall.map((data, index) => (
                          <tbody>
                            <tr>
                              <td>
                                <span>
                                  {data?.chapter?.lesson[0]?.number}
                                  &nbsp;&nbsp;&nbsp;
                                  {data?.chapter?.lesson[0]?.name}
                                </span>
                              </td>
                              <td>
                                <span>{data.score}</span>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                    </table>
                    {totalavg.length > 0 &&
                      totalavg.map((data, index) => (
                        <div className="row view_paragraph">
                          <div className="col-md-6">
                            <span id="view_p1">
                              Total score: {data.total_score}
                            </span>
                          </div>
                          <div className="col-md-6">
                            <span id="view_p2">
                              Average percentage: {data.avg}
                            </span>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="view-all-left-dta">
                    <img src="\SqillUPImages\ScoreCat.svg" alt="" />
                    <h6>To check the score select the given fields.</h6>
                    <p>
                      Lorem ipsum dolor sit amet, consetetur sadips elitr, sed
                      diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat.
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-4">
              <div className="view-all-right-dta ">
                {/* Subject DropDown Id Passing Data */}
                <span
                  style={{ color: "red", fontWeight: "bold" }}
                  className="validate-Dropdown"
                >
                  {" "}
                  {validate}
                </span>
                <select
                  className="dropdown-toggle"
                  name="subject_id"
                  onChange={handleSubjectChange}
                >
                  <option value="">Select-Subject</option>
                  {allSubject.length &&
                    allSubject.map((data, index) => (
                      <option value={data.id}>{data.name}</option>
                    ))}
                </select>

                <select
                  className="dropdown-toggle"
                  name="lesson_type_id"
                  onChange={handleSubjectChange}
                >
                  <option value="">Select Exam Type</option>
                  {examtype.length &&
                    examtype.map((data, index) => (
                      <option value={data.id}>{data.title}</option>
                    ))}
                </select>

                <select
                  className="dropdown-toggle"
                  name="chapter_id"
                  onChange={handleSubjectChange}
                >
                  <option value="">Select Chapter</option>
                  {chapterdata.length &&
                    chapterdata.map((data, index) => (
                      <option value={data.id}>{data.name}</option>
                    ))}
                </select>

                <div className="view-all-right-button">
                  {/* <Link to="/viewallscore">
                    {" "} */}
                  {hidetable ? (
                     <button className="btn" disabled data-bs-toggle="button" style ={{backgroundColor:"#A2A2A2"}}>
                     Apply
                   </button>
                   
                  ) : (
                    <button className="btn" onClick={handelApply}>
                      Apply
                    </button>
                  )}

                  {/* </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewAll;
