import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Courses.css";
// import {AiOutlineCloseCircle} from 'react-icons/ai';
import {AiOutlineCloseCircle} from "@react-icons/all-files/ai/AiOutlineCloseCircle";
function ChapterPaper() {
  return (
    <div className="chapter_close_icon">
      <i>
        {" "}
       <a href="/particularsubject/:id/:chapter"> <AiOutlineCloseCircle/></a>
      </i>
      <div className="chapterpaper-wrapper">
        <div className="chapterpaper-container">
          <div>
            <img src="\SqillUPImages\pdfImage.svg" alt="" />
            <h6>KS3 – Key Stage 3 math chapter Papers!</h6>
            <p>
              Try to finish all lessons in this chapter and continue to do
              particles past paper
            </p>
          </div>
          <div>
            <button>Continue learning</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChapterPaper;
