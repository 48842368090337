import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '../Styles/Signin.css';
import SigninPage from '../Pages/SigninPage';

export default function SignIn() {
  return (
      <>      
      <div className="flex-container">
        <div className="row main-banner">
            <div className="col-md-6 top-p">
                <div className="login-banner"></div>
                <div className="Logo">
                    <img src="\SqillUPImages\Logo Rev A-02.png"alt=""/> 
                </div>
            </div>

            <div className="col-md-6">
               <SigninPage/>
            </div>

        </div>
  </div>
  </>

  )
}
