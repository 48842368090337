import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '../Styles/Help.css';

import {Link} from 'react-router-dom';
function Help() {
  return (
    <>
      <div className="Help-wraper">
        <div className="co-md-12">
          <div className="Help-wrapper-content row">
            <div className="col-md-6">
              <div className="Help_Left_Content">
                <h5>Contact Us</h5>
                <img src="\SqillUPImages\Contact.jpg" alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="Help_right_Content">
                <h5>Send us a message</h5>
                <form className="form-group">
                  <label className="form-label">Your name</label>
                  <input type="text" placeholder="Eg: John Paterson" className="form-control" />
                  <label className="form-label">Your Email</label>
                  <input type="text" placeholder="Eg: John Paterson@gmail.com"className="form-control"/>
                  <label className="form-label">Type your querry</label>
                  <textarea class="form-control" placeholder="Type Message" rows="8"/>
                  <Link to="/helpmessage"><button className="btn ">Send a Message</button></Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Help