import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Courses.css";

import PostQuestion from "./PostQuestion";
import { GenericGet, GenericPost } from "../Services";
import { FaPlusCircle } from "@react-icons/all-files/fa/FaPlusCircle";

import { AiOutlineArrowUp } from "@react-icons/all-files/ai/AiOutlineArrowUp";

function QandA(lessonInfo) {
  const [popUpOpen, setPopupOpen] = useState(false);
  const [Questions, setQuestions] = useState(null);
  const [hidechat, setHideChat] = useState(true);
  const [postcomment, setPostComment] = useState();

  const postQuestionPopup = () => {
    setPopupOpen(true);
  };

  const handelComment = () => {
   
    var FormData = require("form-data");
    var data = new FormData();
    data.append("user_id", "2");
    data.append("lesson_id", "38");
    data.append("contant", "Hi thera");
    GenericPost(data, "createQa").then((data) => {
      setPostComment(data);
    });
  };

  useEffect(() => {
    GenericGet(
      null,
      "viewStudentQnA?lesson_id=" + lessonInfo?.lessonid?.lessonid
    ).then((data) => {
      setQuestions(data);
    });
  }, [popUpOpen]);

  return (
    <div>
      {popUpOpen ? (
        <PostQuestion lessonInfo={lessonInfo} setPopupOpen={setPopupOpen} />
      ) : (
        ""
      )}

      <div className="container">
        <div className="row ">
          <div className="col-md-12 ">
            <div className="question-answers d-flex">
              <div className="col-md-6 pt-1">
                <h5>
                  All questions in this course <span>(10)</span>
                </h5>
              </div>
              <div className="col-md-6 d-grid gap-2 d-md-flex justify-content-md-end">
                <button className="Button" onClick={postQuestionPopup}>
                  <FaPlusCircle />
                  &nbsp;&nbsp;&nbsp;Post questions?
                </button>
              </div>
            </div>
          </div>

          {Questions != null
            ? Questions.map((obj) => {
                return (
                  <div className="row">
                    <div className="QandA-row">
                      <div className="col-md-12 d-flex ">
                        <div className="colmd-8 d-block ">
                          <div className="QandA">
                            <div class="QandA__item">
                              <div class="QandA__column QandA__column--left">
                                <div className="QandA-Image">
                                  <img
                                    src="\SqillUPImages\User-circle.png"
                                    alt=" "
                                  />
                                </div>
                                <div className="QandA__info">
                                  <span class="QandA__question">
                                    {obj.question}
                                  </span>
                                  <span className="QandA__answer">
                                    {/* {obj.question} */}In a solid, particles
                                    are packed tightly together so they don't
                                    move much. The electrons of each atom are
                                    constantly in motion, so the atoms have a
                                    small vibration, but they are fixed in their
                                    position. Because of this, particles in a
                                    solid have very low kinetic energy.
                                  </span>
                                  <div className="QandA-uls_lis d-flex">
                                    <p>{obj.student?.user_name}</p>
                                    <ul className="d-flex">
                                      <li id="list2">{obj.chapter?.name}</li>
                                      <li id="list3">{obj.created_date}</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 d-md-flex justify-content-md-end pt-3">
                          <div>
                            <div className="QandA_tag1">
                              <p>
                                0 <AiOutlineArrowUp />
                              </p>
                            </div>
                            <div className="QandA_tag1">
                              <p
                                onClick={() => setHideChat(false)}
                                style={{ cursor: "pointer" }}
                              >
                                {" "}
                                {obj.reply.length}
                                <img src="\SqillUPImages\Comments.png" alt="" />
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {hidechat ? (
                          ""
                        ) : (
                          <div className="QandA-row">
                            {obj.reply.map((rep) => (
                              <div className="QandA_HideandShow">
                                <div className="QandA__column QandA__column--left">
                                  <div className="QandA-Image">
                                    <img
                                      src="\SqillUPImages\User-circle.png"
                                      alt=""
                                    />
                                  </div>
                                  <div class="QandA__info">
                                    <span class="QandA__question">
                                      {rep.reply}
                                    </span>
                                    <span class="QandA__answer">
                                      {rep.reply}
                                    </span>
                                    <div className="QandA-uls_lis d-flex">
                                      <p>{obj.reply[0]?.user?.first_name}</p>
                                      <ul className="d-flex">
                                        <li id="list2">{obj.chapter?.name}</li>
                                        <li id="list3">
                                          {obj.reply[0]?.created_date}
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                            <div className="QandA-textarea d-flex">
                              <span>
                                <img
                                  src="/SqillUPImages/User-circle-yellow.png"
                                  alt=""
                                />
                              </span>
                              <div className="Text_area">
                                <input
                                  className="input__inner"
                                  style={{ display: "block" }}
                                  type="text"
                                  value={postcomment}
                                />
                              </div>
                              <span style={{ cursor: "pointer" }}>
                                <img
                                  onClick={handelComment}
                                  src="/SqillUPImages/paper-plane.png"
                                  alt=""
                                />
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
            : ""}
        </div>
      </div>
    </div>
  );
}

export default QandA;
