import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Home.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import {
  notice_student,
  greetings_student,
  student_card_score,
  viewSchoolDetails,
  viewStudentUpcomingEvents,
} from "../Services";

import { Link } from "react-router-dom";
import Loader from "../Helpers/Loader";
export default function HomeSection() {
  const [notice, setNotice] = useState([]);
  const [greetings, setGreetings] = useState([]);
  const [cardscore, setCardScore] = useState([]);
  const [schoolName, setSchoolName] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [value, onChange] = useState(new Date());

  const alternatingColor = ["#F98965", "#0A91E7", "#81CCAA", "#FFD952"];

  useEffect(() => {
    notice_student().then((data) => {
      setNotice(data);
    });
    greetings_student().then((data) => {
      setGreetings(data);
    });
    student_card_score().then((data) => {
      setCardScore(data);
    });
    viewSchoolDetails().then((data) => {
      setSchoolName(data);
    });
  }, []);

  return (
    <>
      <div className="container-fluid  ">
        <div className="content-wrapper ">
          <div className="col-md-12 d-flex">
            <div class="row">
              <div class="col-md-8">
                <div className="greetings">
                  {greetings != undefined && (
                    <h5 className="mb-3">
                      {greetings.greeting}, <b>{greetings.name}</b>
                    </h5>
                  )}
                </div>

                <div className="notice-here">
                  {notice.length > 0 ? (
                    <span>{notice.length > 0 && notice[0].title}</span>
                  ) : (
                    <Loader />
                  )}

                  <span id="ellipse">
                    <img src="\SqillUPImages\ellipse.svg" alt="" />
                  </span>
                  <span id="vector">
                    <img src="\SqillUPImages\Vector.svg" alt="" />
                  </span>
                  <span id="Vectorbook">
                    <img src="\SqillUPImages\Vectorbook.svg" alt="" />
                  </span>
                  <p>{notice.length > 0 && notice[0].description}</p>
                </div>

                <div className="my-score">
                  <div className="title mb-2">
                    <h4>My Score</h4>
                    <Link to="/viewall">
                      <span>View all</span>
                    </Link>
                  </div>
                  <div className="d-flex">
                    {cardscore.length &&
                      cardscore.map((data, index) => (
                        <div
                          className="math-card m-2"
                          style={{
                            borderColor:
                              alternatingColor[index % alternatingColor.length],
                          }}
                        >
                          <h6>{data?.subject?.name}</h6>
                          <div className="count">{data.total_score}</div>
                          <p>Maths overall score</p>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div className="calender-wrapper">
                  <div className="calender-details">
                    <p>School</p>
                    <span>{schoolName?.student_detail?.school_name}</span>
                    <h6>Calender</h6>
                  </div>
                  <div className="calender">
                    <Calendar value={value} />
                  </div>
                  <div className="calender-events">
                    <h6>Upcoming Events</h6>
                    <p>
                      <span>03</span>&nbsp;&nbsp;&nbsp;&nbsp;Childrens day
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/*My score end */}
          </div>
        </div>
      </div>
    </>
  );
}
