import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/CoursesTabs.css";
import { getAllQuestion } from "../Services";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function SelfTest() {
  const [questions, setQuestions] = useState([]);
  const [selectIndex, setSelectIndex] = useState(0);
  const [disable, setDisable] = useState(false);
  const [viewResult, setViewResult] = useState(false);
  const [resultRes, setResultRes] = useState([]); //Store the Useresponse
  const [resIndex, setResIndex] = useState(0);
  const location = useLocation();

  useEffect(() => {
    getAllQuestion(location.state, "viewSelfTest").then((data) => {
      setQuestions(data);
    });
  }, []);

  // Options Alphabets
  const handleSubmit = (answer, index) => {
    setResIndex(resIndex + 1);
    setDisable(true);
    let data = questions[0].questions_data[selectIndex];
    let resHistory = resultRes;
    if (data?.correct_answers[0]?.answer === answer) {
      let obj = {
        user_res: answer,
        result: "correct",
        solutions: data.solution,
      };
      resHistory.push(obj);
      setResultRes(resHistory);
    } else {
      let obj = {
        user_res: answer,
        result: "wrong",
        solutions: data.solution,
      };
      resHistory.push(obj);
      setResultRes(resHistory);
    }

    setViewResult(false);
  };

  const alph = ["A", "B", "C", "D"];

  // Next Button Changer
  const handelClickNext = (e) => {
    if (resultRes.length) {
      if (selectIndex - resultRes.length < 0) {
        setSelectIndex(selectIndex + 1);
        setViewResult(false);
        selectIndex + 1 === resultRes.length && setDisable(false);
      }
    }
  };
  //Previous Button Changer
  const handelClickPrevious = (e) => {
    setSelectIndex(selectIndex - 1);
    setDisable(true);
    setViewResult(false);
  };

  const handleViewResult = () => {
    if (resultRes.length) {
      if (resultRes.length >= selectIndex) {
        setViewResult(true);
        setDisable(true);
      }
    }
  };

  let navigate = useNavigate();
  const handelClickSubmit = () => {
    let correct = 0;
    resultRes.map((res) => {
      if (res.result === "correct") {
        correct = correct + 1;
      }
    });
    let obj = {
      result: correct,
      total: questions[0]?.questions?.length,
      lessonId: location.state.lessonId.lessonid,
    };
    navigate("/submit", { state: obj });
  };

  return (
    <>
      <div className="selftest_container">
        <div className="col-md-12 d-flex">
          <div className="col-md-3 pl-3">
            <div className="selftest-time">
              <button>
                <time>00:40</time>
              </button>
            </div>
          </div>
          {/* Pagination Data */}

          <div className="col-md-6 ">
            <div id="pagination">
              {questions[0]?.questions.map((data, index) => (
                <span
                  className={`blocks active ${
                    resultRes.length && resultRes[index]
                      ? resultRes[index]?.result === "correct"
                        ? "bg_success correct_answers"
                        : "bg_fail wrong_answers"
                      : ""
                  }`}
                >
                  {index + 1}
                </span>
              ))}
            </div>
          </div>
          <div className="col-md-3 d-md-flex justify-content-md-end ">
            <div className="selftest_CloseButton">
              <Link to="/particularsubject/38/24">
                <button className="btn">Close</button>
              </Link>
            </div>
          </div>
        </div>

        {/* 5. Question Card   */}
        <div className="question-card">
          <h3 className="question-text">
            {questions.length > 0 && (
              <div
                id="question_description"
                dangerouslySetInnerHTML={{
                  __html: `
                  ${questions[0]?.questions_data[selectIndex]?.question_number} : ${questions[0]?.questions_data[selectIndex].question} `,
                }}
              ></div>
            )}
          </h3>
          <span className="question-image">
            <img src="\SqillUPImages\Sample-Test.jpg" alt="" />
          </span>

          {/* List of options  */}
          <div className="selftest_list_options">
            <ul>
              {questions.length > 0 &&
                questions[0]?.questions_data[selectIndex]?.answers.map(
                  (option, index) => (
                    <li
                      className={
                        disable
                          ? `curser_event ${
                              resultRes[selectIndex].user_res === option.answer
                                ? resultRes[selectIndex].result === "correct"
                                  ? "bg_success"
                                  : "bg_fail"
                                : ""
                            }`
                          : "cursor_pointer"
                      }
                      onClick={() => handleSubmit(option.answer, index)}
                    >
                      {alph[index]} : {option.answer}
                    </li>
                  )
                )}
            </ul>
          </div>
          <div className="selftest_Submit_Buttons">
            <button
              className={selectIndex > 0 ? "btn" : "curser_event"}
              id="Previous"
              onClick={() => handelClickPrevious()}
            >
              Previous
            </button>
            <button
              className="btn"
              id="ViewSolution"
              onClick={() => {
                handleViewResult();
              }}
            >
              View Solution
            </button>
            {questions[0]?.questions.length === resultRes.length ? (
              <button
                className="btn"
                id="next"
                onClick={() => handelClickSubmit()}
              >
                Submit
              </button>
            ) : (
              <button
                className={
                  questions[0]?.questions.length - 1 > selectIndex
                    ? "btn"
                    : "curser_event"
                }
                id="next"
                onClick={() => handelClickNext()}
              >
                Next
              </button>
            )}
          </div>
          {resultRes.length > 0 &&
          resultRes[selectIndex]?.result === "correct" ? (
            <div className="selftest-Tickmark">
              <img src="\SqillUPImages\Tickmark.svg" alt="" />
              Correct
            </div>
          ) : resultRes[selectIndex]?.result === "wrong" ? (
            <div className="selftest-Incorrect-Tickmark">
              <img src="\SqillUPImages\path 3052.svg" alt="" />
              Incorrect
            </div>
          ) : null}

          <div className="col-md-12">
            <div>Result:</div>
            <div
              id="question_solution"
              dangerouslySetInnerHTML={{
                __html: `
                  ${
                    resultRes.length > 0 &&
                    resultRes[selectIndex]?.solutions &&
                    viewResult
                      ? resultRes[selectIndex]?.solutions
                      : ''
                  } `,
              }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SelfTest;
